import { configureStore } from "@reduxjs/toolkit";
import userReducer from "../features/user/userSlice";
import inspectionReducer from "../features/inspection/inspectionSlice";
import appDataReducer from "../features/appData/appDataSlice";
import apiDataReducer from "../features/apiData/apiDataSlice";

const store = configureStore({
  reducer: {
    user: userReducer,
    inspection: inspectionReducer,
    appData: appDataReducer,
    apiData: apiDataReducer,
  },
});

export default store;
