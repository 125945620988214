import React, { useEffect, useRef, useState } from "react";
import ic_camera from "../icons/ic_camera.png";
import ic_error from "../icons/ic_error.png";

import "./cameraButton.css";
import { useDispatch, useSelector } from "react-redux";
import { addAppData } from "../features/appData/appDataSlice";
import { auth } from "../firebase/config";
import { BackendAPI } from "../api";
import Loader from "./loader";
import FullSizeImageModal from "../modals/fullSizeImageModal";
import PopupModal from "../modals/popupModal";

const CameraButton = ({
  buttonText = "Capture",
  sampleImage,
  onCapture = () => {},
  errored = false,
  errorMessage,
  key1 = "",
  required = false,
  imageSelected,
  hidden = false,
  parent = "",
  disabled = false,
}) => {
  const selectedImage = useSelector((st) => st.appData?.[key1]);
  const sampleImageLink = useSelector((st) => st.appData?.[key1 + "_sample"]);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sampleImageLoading, setSampleImageLoading] = useState(false);
  const [clickPosition, setClickPosition] = useState({ X: 0, Y: 0 });
  const [openImage, setOpenImage] = useState();
  const [uploading, setUploading] = useState(false);
  const cameraRef = useRef();
  const fileExplorerRef = useRef();
  const pickerRef = useRef();
  const cameraContainerRef = useRef();
  const [showPickerModal, setShowPickerModal] = useState(false);
  const [pickerModalPositionY, setPickerModalPositionY] = useState("bottom");
  const [pickerModalPositionX, setPickerModalPositionX] = useState("right");
  const [pickerModalStart, setPickerModalStart] = useState(20);
  const [cameraContainerRefPos, setCameraContainerRefPos] = useState({
    start: 0,
    end: 0,
  });
  const signedPhotosTracker = useSelector((st) => st.appData);
  const dispatch = useDispatch();

  useEffect(() => {
    getSampleImage();
    var listener = document.addEventListener("click", updateClickedPos);

    return () => {
      document.removeEventListener("click", updateClickedPos);
    };
  }, []);

  useEffect(() => {
    if (clickPosition.Y + 180 >= window.outerHeight) {
      setPickerModalPositionY("top");
      setPickerModalStart(clickPosition.Y - 120);
    } else {
      setPickerModalPositionY("bottom");
      setPickerModalStart(clickPosition.Y + 20);
    }
    if (
      clickPosition.X + 140 >= window.outerWidth &&
      pickerModalPositionX === "right"
    ) {
      setPickerModalPositionX("left");
    } else if (pickerModalPositionX === "left") {
      setPickerModalPositionX("right");
    }
    setCameraContainerRefPos({ start: clickPosition.Y, end: clickPosition.Y });
  }, [clickPosition]);

  const updateClickedPos = (e) => {
    if (e) {
      setClickPosition({ X: e.clientX, Y: e.clientY });
    }
  };

  const getSampleImage = async () => {
    if (!sampleImageLink && sampleImage) {
      setSampleImageLoading(true);
      var key = key1 + "_sample";
      if (sampleImage) {
        if (String(sampleImage).startsWith("http")) {
          setLoading(false);
          setSampleImageLoading(false);
          dispatch(addAppData({ [key]: sampleImage }));
        } else {
          setSampleImageLoading(true);
          if (!signedPhotosTracker[key]) {
            var data1 = {};
            try {
              var token = await auth.currentUser.getIdToken();
              var configs = {
                headers: {
                  Authorization: `bearer ${token}`,
                },
              };
              data1[key] = "loading";
              dispatch(addAppData(data1));
              var resp = await BackendAPI.get(
                `/inspection/image/get_url?path=${sampleImage}`,
                configs
              );
              if (resp.status === 200) {
                data1[key] = resp.data.data.url;
                dispatch(addAppData(data1));
              } else {
                data1[key] = null;
                dispatch(addAppData(data1));
                setSampleImageLoading(false);
                return null;
              }
              setSampleImageLoading(false);
            } catch (error) {
              console.log(error);
              data1[key] = null;
              dispatch(addAppData(data1));
              setSampleImageLoading(false);
            }
          }
        }
      } else {
        setSampleImageLoading(false);
        dispatch(addAppData({ [key]: null }));
      }
    }
  };

  useEffect(() => {
    getImageLink(imageSelected, key1);
  }, [imageSelected]);

  useEffect(() => {
    if (errored) {
      setLoading(false);
    }
  }, [errored, errorMessage]);

  const getImageLink = async (data, code) => {
    if (!selectedImage) {
      var key = code;
      if (data) {
        if (String(data).startsWith("http")) {
          setLoading(false);
          dispatch(addAppData({ [key]: data }));
        } else {
          setLoading(true);
          if (!signedPhotosTracker[key]) {
            var data1 = {};
            try {
              var token = await auth.currentUser.getIdToken();
              var configs = {
                headers: {
                  Authorization: `bearer ${token}`,
                },
              };
              data1[key] = "loading";
              dispatch(addAppData(data1));
              var resp = await BackendAPI.get(
                `/inspection/image/get_url?path=${data}`,
                configs
              );
              if (resp.status === 200) {
                data1[key] = resp.data.data.url;
                dispatch(addAppData(data1));
              } else {
                data1[key] = null;
                dispatch(addAppData(data1));
                setLoading(false);
                return null;
              }
              setLoading(false);
            } catch (error) {
              console.log(error);
              data1[key] = null;
              dispatch(addAppData(data1));
              setLoading(false);
            }
          }
        }
      } else {
        setLoading(false);
        dispatch(addAppData({ [key]: null }));
      }
    }
  };

  const openCamera = () => {
    setShowPickerModal(false);
    if (cameraRef.current) {
      cameraRef.current.click();
    }
  };

  const openFileExplorer = () => {
    setShowPickerModal(false);
    if (fileExplorerRef.current) {
      fileExplorerRef.current.click();
    }
  };

  const setPhoto = (e) => {
    setShowPickerModal(false);
    if (e.target.files && e.target.files.length > 0) {
      var key = key1;
      dispatch(addAppData({ [key]: null }));
      onCapture(e, setLoading);
      setUploading(true);
      setLoading(true);
    }
  };

  const pickerTypeSelector = () => {
    return (
      <div className="picker-type-container" ref={pickerRef}>
        <div
          className="picker-camera"
          onClick={() => {
            if (!disabled) openCamera();
          }}
        >
          Camera
        </div>
        <div
          className="picker-file-selector"
          onClick={() => {
            if (!disabled) openFileExplorer();
          }}
        >
          File Selector
        </div>
      </div>
    );
  };

  const openPickerModal = () => {
    setCameraContainerRefPos({
      start: clickPosition.Y,
      end: clickPosition.Y,
    });
    setShowPickerModal(true);
  };

  return (
    <div id={key1}>
      {hidden ? (
        <></>
      ) : (
        <>
          <FullSizeImageModal
            open={openModal}
            closeModal={setOpenModal}
            image={openImage}
          />
          <PopupModal
            show={showPickerModal}
            containerY={cameraContainerRefPos}
            pointerX={clickPosition.X}
            positionX={pickerModalPositionX}
            children={pickerTypeSelector()}
            containerStart={pickerModalStart}
            handleClose={() => setShowPickerModal(false)}
            positionY={pickerModalPositionY}
          />
          <div className="camera-container" ref={cameraContainerRef}>
            <div className="image-container">
              {loading ? (
                <Loader className="camera-image" />
              ) : errored ? (
                <img
                  src={ic_error}
                  className="camera-image"
                  onClick={() => {
                    if (!disabled) openPickerModal();
                  }}
                />
              ) : selectedImage ? (
                <img
                  src={selectedImage}
                  className="camera-selected-image"
                  onClick={() => {
                    setOpenImage(selectedImage);
                    setOpenModal(true);
                  }}
                />
              ) : (
                <img
                  src={ic_camera}
                  className="camera-image"
                  onClick={() => {
                    if (!disabled) openPickerModal();
                  }}
                />
              )}
            </div>
            <div
              className="button-text-container"
              onClick={() => {
                if (!disabled && sampleImageLink) openPickerModal();
              }}
            >
              <p className="button-text">
                {buttonText}
                {required ? " *" : ""}
              </p>
            </div>
            <div
              className="end-button-container"
              onClick={
                selectedImage
                  ? () => {
                      if (!disabled) openPickerModal();
                    }
                  : sampleImageLink
                  ? () => {
                      setOpenImage(sampleImageLink);
                      setOpenModal(true);
                    }
                  : () => {
                      if (!disabled) openPickerModal();
                    }
              }
            >
              <div className="end-button-text button-text">
                {sampleImageLoading ? (
                  <Loader />
                ) : selectedImage ? (
                  "Re-capture"
                ) : sampleImageLink ? (
                  "Sample Image"
                ) : (
                  "Capture"
                )}
              </div>
            </div>
          </div>
          <input
            accept="image/*"
            capture="environment"
            className="camera-input-field"
            type="file"
            ref={cameraRef}
            onChange={setPhoto}
          />
          <input
            accept="image/*"
            className="camera-input-field"
            type="file"
            ref={fileExplorerRef}
            onChange={setPhoto}
          />
          {errorMessage ? (
            <p className="camera-error-message">{errorMessage}</p>
          ) : null}
        </>
      )}
    </div>
  );
};

export default CameraButton;
