import React, { useState } from "react";
import FilledInput from "@mui/material/FilledInput";
import { styled } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import {
  ERROR_LIGHT,
  GREY_STROKE,
  PRIMARY_1_SHADE_1,
  TEXT_HEADING,
  TEXT_SECONDARY,
} from "../constants/colors";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import "./inputField.css";
import { StyledEngineProvider } from "@mui/material/styles";
import SupportingImageIcon from "./SupportingImageIcon";

const CustomFormControl = styled((props) => (
  <FormControl {...props} sx={{ width: "100%" }} variant="filled" />
))(({ theme }) => ({
  "& .MuiFormLabel-root": {
    color: TEXT_SECONDARY,
    "& .Mui-focused": {
      color: TEXT_HEADING,
    },
  },
  "& .MuiInputLabel-root": {
    "& .Mui-focused": {
      color: TEXT_HEADING,
    },
  },
}));

const CustomTextField = styled((props) => <FilledInput {...props} />)(
  ({ theme }) => ({
    "& .MuiInputBase-root-MuiFilledInput-root": {
      overflow: "hidden",
      borderRadius: "4px 4px 0 0",
      backgroundColor: PRIMARY_1_SHADE_1,
      color: TEXT_HEADING,
      border: 0,
      fontWeight: 600,
      borderBottom: "1px solid",
      borderColor: GREY_STROKE,
      "&.Mui-focused": {
        backgroundColor: PRIMARY_1_SHADE_1,
        color: TEXT_HEADING,
        borderColor: TEXT_HEADING,
      },
    },
    "& .MuiFilledInput-input": {
      fontWeight: 600,
    },
    "& .Mui-disabled": {
      fontWeight: 600,
      border: 0,
      borderBottom: "1px solid",
      borderColor: GREY_STROKE,
      backgroundColor: "transparent",
    },
    "& .Mui-error": {
      backgroundColor: ERROR_LIGHT,
    },
  })
);

const CustomInputLabel = styled((props) => <InputLabel {...props} />)(
  ({ theme }) => ({
    "& .MuiInputLabel-root": {
      color: TEXT_SECONDARY,
      "& .Mui-focused": {
        color: TEXT_HEADING,
      },
    },
    "& .MuiFormLabel-root": {
      color: TEXT_SECONDARY,
      "& .Mui-focused": {
        color: TEXT_HEADING,
      },
    },
  })
);

const InputField = ({
  placeholder = "",
  type = "text",
  validation,
  value = "",
  endButton,
  disabled = false,
  onChange = () => {},
  isError = false,
  hidden = false,
  errorMessage,
  id = "input",
  supportingPhoto = false,
  supportingImage,
  key1 = "input",
  autocomplete = "",
  onClick = () => {},
  onFocus = () => {},
  onSupportingImageChange = () => {},
  capital = false,
}) => {
  const onValueChange = (e) => {
    onChange(e);
  };

  return (
    <StyledEngineProvider injectFirst>
      {hidden ? (
        <></>
      ) : (
        <CustomFormControl error={isError}>
          <CustomInputLabel disabled={disabled} error={isError} htmlFor={id}>
            {placeholder}{" "}
            {supportingPhoto ? (
              <SupportingImageIcon
                key1={key1}
                supportingImage={supportingImage}
                onClick={onSupportingImageChange}
              />
            ) : null}
          </CustomInputLabel>

          <CustomTextField
            id={id}
            label={placeholder}
            variant="filled"
            type={type}
            value={value ? value : ""}
            onClick={onClick}
            onChange={onValueChange}
            disabled={disabled}
            autoComplete={autocomplete}
            sx={{
              ".MuiFilledInput-input": {
                textTransform: capital ? "uppercase" : "none",
              },
            }}
            endAdornment={
              endButton ? (
                <InputAdornment position="end">{endButton}</InputAdornment>
              ) : null
            }
            error={isError}
          />
          {isError ? <p className="error-text">{errorMessage}</p> : null}
        </CustomFormControl>
      )}
    </StyledEngineProvider>
  );
};

export default InputField;
