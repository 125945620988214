import React, { useEffect, useState } from "react";
import Header from "../components/header";
import LogoutButton from "../components/logoutButton";
import CustomButton from "../components/customButton";
import { useNavigate } from "react-router-dom";
import ic_success from "../icons/ic_success.png";
import { useCookies } from "react-cookie";

import style from "../styles/inspectionSubmit.module.css";
import HorizontalDivider from "../components/horizontalDivider";
import { auth } from "../firebase/config";
import SingleOptionModal from "../modals/singleOptionModal";
import { useDispatch, useSelector } from "react-redux";
import { clearDetails } from "../features/inspection/inspectionSlice";
import { logoutUser } from "../features/user/userSlice";
import { resetAppData } from "../features/appData/appDataSlice";

const InspectionSubmit = () => {
  const [cookie, setCookie, deleteCookie] = useCookies(["electrifi-mobility"]);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const inspectionData = useSelector((st) => st.inspection);
  const userData = useSelector((st) => st.user);
  const navigation = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (cookie && cookie["signin-status"]) {
      setTimeout(() => {
        if (!auth.currentUser) {
          setShowLogoutModal(true);
        } else {
          setCookie("uid", auth.currentUser.uid, {
            path: "/",
            maxAge: 60 * 60 * 24,
          });
          setCookie("signin-status", true, { path: "/", maxAge: 60 * 60 * 24 });
        }
      }, 5000);
    } else {
      setTimeout(() => {
        if (auth.currentUser) {
          setCookie("uid", auth.currentUser.uid, {
            path: "/",
            maxAge: 60 * 60 * 24,
          });
          setCookie("signin-status", true, { path: "/", maxAge: 60 * 60 * 24 });
        } else {
          setShowLogoutModal(true);
        }
      }, 3000);
    }
  }, []);

  const logout = () => {
    deleteCookie("uid");
    deleteCookie("signin-status");
    deleteCookie("name");
    dispatch(clearDetails());
    dispatch(resetAppData());
    dispatch(logoutUser());
    navigation("/login");
  };

  return (
    <div className={style.overall_success_container}>
      <Header
        headerDescription={
          cookie && cookie["name"]
            ? cookie["name"]
            : userData.name
            ? userData.name
            : ""
        }
        endItem={<LogoutButton />}
        marginHorizontal="4%"
        marginTop="4%"
        width="92%"
      />
      <SingleOptionModal
        show={showLogoutModal}
        title="You have been logged out. Please login again"
        buttonText="Login"
        onClick={logout}
      />
      <HorizontalDivider />
      <div className={style.submit_container}>
        <div className={style.success_icon_container}>
          <img className={style.success_icon} src={ic_success} />
        </div>
        <div className={style.success_title}>
          Inspection submitted Successfully
        </div>
        <div className={style.success_subtitle}>
          ID: {inspectionData.inspection_id} | {inspectionData.asset_ownership}{" "}
          EV
        </div>
        <CustomButton
          buttonText="Start another Inspection"
          onClick={() => {
            dispatch(clearDetails());
            dispatch(resetAppData());
            navigation("/select-asset");
          }}
          additionalStyles={{ height: "60px" }}
        />
      </div>
    </div>
  );
};

export default InspectionSubmit;
