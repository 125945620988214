import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./screens/login";
import AssetSelection from "./screens/assetSelection";
import SearchElectrifiAsset from "./screens/searchElectrifiAsset";
import AssetInspection from "./screens/assetInspection";
import { auth, app } from "./firebase/config";
import InspectionSubmit from "./screens/inspectionSubmit";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<AssetSelection />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/select-asset" element={<AssetSelection />} />
        <Route exact path="/search-asset" element={<SearchElectrifiAsset />} />
        <Route exact path="/asset/inspection" element={<AssetInspection />} />
        <Route exact path="/asset/submit" element={<InspectionSubmit />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
