import { Modal } from "@mui/material";
import React, { useRef, useState } from "react";
import Loader from "../components/loader";
import { TEXT_HEADING } from "../constants/colors";
import "./loaderModal.css";
import { StyledEngineProvider } from "@mui/material/styles";

const LoaderModal = ({ show = false }) => {
  const [catchyLines, setCatchyLines] = useState([
    "Warming up the engines!",
    "Checking what's under the hood",
    "Hold on! Battery is still charging",
    "Did you say EV - Extraordinary Vehicle",
  ]);

  const ind = useRef(Math.floor(Math.random() * 4));

  return (
    <StyledEngineProvider injectFirst>
      <Modal open={show} className="modal" onClose={() => {}}>
        <div className="parent-container">
          <Loader size="40px" />
          <p className="catchy-line">{catchyLines[ind.current]}</p>
        </div>
      </Modal>
    </StyledEngineProvider>
  );
};

export default LoaderModal;
