import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import GoogleIcon from "../components/googleIcon";
import InputField from "../components/inputField";
import {
  PRIMARY_1_SHADE_1,
  PRIMARY_1_SHADE_2,
  TEXT_HEADING,
  TEXT_SECONDARY,
} from "../constants/colors";
import CustomButton from "../components/customButton";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";
import {
  RecaptchaVerifier,
  signInWithPhoneNumber,
  OAuthProvider,
  signInWithPopup,
  getAdditionalUserInfo,
} from "firebase/auth";
import { auth } from "../firebase/config.js";

import I_login from "../illustrations/I_login.png";

import style from "../styles/login.module.css";
import Header from "../components/header.js";
import DividerWithText from "../components/dividerWithText.js";
import ToastMessage from "../components/toastMessage.js";
import ic_edit from "../icons/ic_edit.png";
import { useCookies } from "react-cookie";
import { BackendAPI } from "../api";
import Loader from "../components/loader.js";
import { useDispatch } from "react-redux";
import { updateUser } from "../features/user/userSlice.js";

const GoogleButton = styled(Button)(({ theme }) => ({
  backgroundColor: "transparent",
  borderColor: "black",
  borderRadius: "100px",
  height: "48px",
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  width: "100%",
  color: TEXT_HEADING,
  fontWeight: "600",
  fontSize: "small",
}));

const Login = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [OTP, setOTP] = useState("");
  const [otpSent, setOTPSent] = useState(false);
  const [updateTimer, setUpdateTimer] = useState(0);
  const [otpResendTimer, setOTPResendTimer] = useState();
  const [time30Sec, setTime30Sec] = useState();
  const [erroredField, setErroredField] = useState(""); // Values can be either phone or otp to work, else would not affect anything
  const [errorMessage, setErrorMessage] = useState();
  const [showToast, setShowToast] = useState(false);
  const [toastMsg, setToastMsg] = useState("Success");
  const [toastType, setToastType] = useState("success"); //success, error, warning, info
  const [cookie, setCookie] = useCookies(["electrifi-mobility"]);
  const [buttonLoaders, setButtonLoaders] = useState({
    google: false,
    signin: false,
    otp: false,
  });
  const dispatch = useDispatch();

  const navigation = useNavigate();

  useEffect(() => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      auth,
      "sign-in-with-phone-button",
      {
        size: "invisible",
        callback: (response) => {
          console.log("captcha success");
        },
      }
    );
  }, []);

  useEffect(() => {
    var interval;
    if (time30Sec) {
      var dateNow = new Date();
      var secondsLeft = new Date(time30Sec - dateNow).getSeconds();
      if (secondsLeft === 0) {
        setOTPResendTimer(null);
        clearInterval(interval);
      } else {
        var textField = " in 00:";
        if (secondsLeft < 10) {
          textField = textField + "0" + String(secondsLeft);
        } else {
          textField = textField + String(secondsLeft);
        }
        setOTPResendTimer(textField);
      }
      interval = setInterval(() => {
        var dateNow = new Date();
        var secondsLeft = new Date(time30Sec - dateNow).getSeconds();
        if (secondsLeft === 0) {
          setOTPResendTimer(null);
          clearInterval(interval);
        } else {
          var textField = " in 00:";
          if (secondsLeft < 10) {
            textField = textField + "0" + String(secondsLeft);
          } else {
            textField = textField + String(secondsLeft);
          }
          setOTPResendTimer(textField);
        }
      }, 1000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [time30Sec]);

  useEffect(() => {
    if (updateTimer !== 0) {
      var dateNow = new Date();
      var date30Sec = dateNow.setSeconds(dateNow.getSeconds() + 30);
      setTime30Sec(new Date(date30Sec));
    }
  }, [updateTimer]);

  const autoReadOTP = async () => {
    const signal = new AbortController();
    setTimeout(() => {
      signal.abort();
    }, 1 * 60 * 1000);
    if ("OTPCredential" in window) {
      console.log("has credentials");
      try {
        if (navigator.credentials) {
          try {
            await navigator.credentials
              .get({ abort: signal.signal, otp: { transport: ["sms"] } })
              .then((content) => {
                if (content && content.code) {
                  setOTP(content.code);
                }
              });
          } catch (error) {
            console.log("Got error");
            console.log(error);
          }
        }
      } catch (error) {
        console.log("Got error");
        console.log(error);
      }
    }
  };

  const loginUser = (e) => {
    if (OTP && OTP.length === 6 && /^\d+$/.test(OTP)) {
      setButtonLoaders({ ...buttonLoaders, signin: true });
      window.confirmationResult
        .confirm(OTP)
        .then(async (result) => {
          const uid = result.user.uid;
          const token = await result.user.getIdToken();
          const config = {
            headers: {
              "Content-Type": "application/json",
              Authorization: `bearer ${token}`,
            },
          };
          updateUser({
            uid: uid,
            token: token,
            loggedIn: true,
          });
          BackendAPI.post(
            "/assetmanagement/uid/update",
            JSON.stringify({ phone: phoneNumber, uid: uid }),
            config
          )
            .then((response) => response.data)
            .then((data) => {
              if (data?.code === 200) {
                setCookie("uid", auth.currentUser.uid, {
                  path: "/",
                  maxAge: 60 * 60 * 24,
                });
                setCookie("signin-status", true, {
                  path: "/",
                  maxAge: 60 * 60 * 24,
                });
                navigation("/select-asset");
                setErrorMessage(null);
                setErroredField("");
                setButtonLoaders({ ...buttonLoaders, signin: false });
              } else {
                setErrorMessage(
                  "Server error. Please contact the administrator"
                );
                setErroredField("otp");
                setButtonLoaders({ ...buttonLoaders, signin: false });
              }
            })
            .catch((err) => {
              setErrorMessage("Facing network issues. Please try again");
              setErroredField("otp");
              setButtonLoaders({ ...buttonLoaders, signin: false });
            });
        })
        .catch((err) => {
          setToastMsg("Please enter the correct OTP");
          setToastType("error");
          setShowToast(true);
          setButtonLoaders({ ...buttonLoaders, signin: false });
        });
    } else {
      setErroredField("otp");
      setErrorMessage("Please enter a valid 6 digit OTP");
    }
  };

  const getOTP = (e) => {
    if (!otpSent) {
      if (
        phoneNumber &&
        phoneNumber.length === 10 &&
        /^\d+$/.test(phoneNumber)
      ) {
        setButtonLoaders({ ...buttonLoaders, otp: true });
        BackendAPI.get(
          `/assetmanagement/user-permission/search?phone=${phoneNumber}`
        )
          .then((response) => {
            if (response.status === 500) {
              setErroredField("phone");
              setErrorMessage("Facing network issues. Please try again");
              return "Network Error";
            }
            return response.data;
          })
          .then((data) => {
            if (data === "Network Error") {
              setButtonLoaders({ ...buttonLoaders, otp: false });
              return;
            }
            if (data && data.code === 200) {
              if (
                data.data.details.results.permissions.includes(
                  "inspection_app|login|phone"
                )
              ) {
                // Run logic to get OTP for the user
                dispatch(
                  updateUser({
                    name: data.data.details.results.name,
                    phoneNumber: phoneNumber,
                  })
                );
                setCookie("name", data.data.details.results.name, {
                  path: "/",
                  maxAge: 60 * 60 * 24,
                });
                const appVerifier = window.recaptchaVerifier;
                signInWithPhoneNumber(auth, "+91" + phoneNumber, appVerifier)
                  .then((confirmationResult) => {
                    window.confirmationResult = confirmationResult;
                    setUpdateTimer(updateTimer + 1);
                    setErrorMessage(null);
                    setErroredField("");
                    setOTPSent(true);
                    setToastMsg("OTP sent successfully!");
                    autoReadOTP();
                    setToastType("success");
                    setShowToast(true);
                    setButtonLoaders({ ...buttonLoaders, otp: false });
                  })
                  .catch((error) => {
                    console.log(error);
                    setToastMsg("Facing issues while sending OTP");
                    setToastType("error");
                    setShowToast(true);
                    setButtonLoaders({ ...buttonLoaders, otp: false });
                  });
              } else {
                setErroredField("phone");
                setErrorMessage(
                  "Authorization failed. Please contact Asset manager"
                );
                setButtonLoaders({ ...buttonLoaders, otp: false });
              }
            } else {
              setErroredField("phone");
              setErrorMessage(
                "Authorization failed. Please contact Asset manager"
              );
              setButtonLoaders({ ...buttonLoaders, otp: false });
            }
          })
          .catch((error) => {
            console.error("Error fetching permissions:", error);
            setErroredField("phone");
            setErrorMessage("Facing network issues. Please try again");
            setButtonLoaders({ ...buttonLoaders, otp: false });
          });
      } else {
        setErroredField("phone");
        setErrorMessage("Please enter a valid 10 digit number");
      }
    }
  };

  const resendOTP = (e) => {
    if (!otpResendTimer) {
      setButtonLoaders({ ...buttonLoaders, otp: true });
      const appVerifier = window.recaptchaVerifier;
      signInWithPhoneNumber(auth, "+91" + phoneNumber, appVerifier)
        .then((confirmationResult) => {
          window.confirmationResult = confirmationResult;
          setUpdateTimer(updateTimer + 1);
          setErrorMessage(null);
          setErroredField("");
          setOTPSent(true);
          setToastMsg("OTP sent successfully!");
          setToastType("success");
          autoReadOTP();
          setShowToast(true);
          setButtonLoaders({ ...buttonLoaders, otp: false });
        })
        .catch((error) => {
          console.log(error);
          setToastMsg("Facing issues while sending OTP");
          setToastType("error");
          setShowToast(true);
          setButtonLoaders({ ...buttonLoaders, otp: false });
        });
    }
  };

  const phoneNumberUpdate = (e) => {
    var val = e.target?.value;
    if (val && val.length <= 10) {
      setPhoneNumber(val);
    } else if (val && val.length > 10) {
      setPhoneNumber(val.substring(0, 10));
    } else {
      setPhoneNumber(val);
    }
  };

  const OTPUpdate = (e) => {
    var val = e.target?.value;
    if (val && val.length <= 6) {
      setOTP(val);
    } else if (val && val.length > 6) {
      setOTP(val.substring(0, 6));
    } else {
      setOTP(val);
    }
  };

  const signInWithGoogleApp = () => {
    const provider = new OAuthProvider("oidc.electrifi-google-sso");
    provider.addScope("https://www.googleapis.com/auth/userinfo.email");
    provider.addScope(" https://www.googleapis.com/auth/userinfo.profile");
    setButtonLoaders({ ...buttonLoaders, google: true });
    signInWithPopup(auth, provider)
      .then(async (result) => {
        const credential = OAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        const user = result.user;
        const metadata = getAdditionalUserInfo(result);
        const userEmail = user.email;
        const uid = result.user.uid;
        const token1 = await user.getIdToken();
        BackendAPI.get(
          `/assetmanagement/user-permission/search?email=${userEmail}`
        )
          .then((response) => {
            if (response.status === 500) {
              setToastMsg("Facing network issues. Please try again");
              setToastType("error");
              setShowToast(true);
              return "Network Error";
            }
            return response.data;
          })
          .then((data) => {
            if (data === "Network Error") {
              setButtonLoaders({ ...buttonLoaders, google: false });
              return;
            }
            if (data && data.code === 200) {
              if (
                data.data.details.results.permissions.includes(
                  "inspection_app|login|email"
                )
              ) {
                dispatch(
                  updateUser({
                    name: data.data.details.results.name,
                    phoneNumber: phoneNumber,
                    uid: uid,
                    token: token1,
                    email: userEmail,
                    loggedIn: true,
                  })
                );
                setCookie("name", data.data.details.results.name, {
                  path: "/",
                  maxAge: 60 * 60 * 24,
                });
                const config = {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `bearer ${token1}`,
                  },
                };

                BackendAPI.post(
                  "/assetmanagement/uid/update",
                  JSON.stringify({ email: userEmail, uid: uid }),
                  config
                )
                  .then((response) => response.data)
                  .then((data) => {
                    if (data.code === 200) {
                      setCookie("uid", auth.currentUser.uid, {
                        path: "/",
                        maxAge: 60 * 60 * 24,
                      });
                      setCookie("signin-status", true, {
                        path: "/",
                        maxAge: 60 * 60 * 24,
                      });
                      navigation("/select-asset");
                      setToastMsg("Signed in Successfully!");
                      setToastType("success");
                      setShowToast(true);
                      setButtonLoaders({ ...buttonLoaders, google: false });
                    } else {
                      setToastMsg(
                        "Server error. Please contact the administrator"
                      );
                      setToastType("error");
                      setShowToast(true);
                      setButtonLoaders({ ...buttonLoaders, google: false });
                    }
                  })
                  .catch((err) => {
                    setToastMsg("Facing network issues. Please try again");
                    setToastType("error");
                    setShowToast(true);
                    setButtonLoaders({ ...buttonLoaders, google: false });
                  });
              } else {
                setToastMsg(
                  "Authorization failed. Please contact Asset manager"
                );
                setToastType("error");
                setShowToast(true);
                setButtonLoaders({ ...buttonLoaders, google: false });
                auth.currentUser
                  .delete()
                  .then(() => {
                    console.log("Account deleted");
                  })
                  .catch((error) => {
                    console.error("Error deleting user account:", error);
                  });
              }
            } else {
              setToastMsg("Authorization failed. Please contact Asset manager");
              setToastType("error");
              setShowToast(true);
              setButtonLoaders({ ...buttonLoaders, google: false });
              auth.currentUser
                .delete()
                .then(() => {
                  console.log("Account deleted");
                })
                .catch((error) => {
                  console.error("Error deleting user account:", error);
                });
            }
          });
      })
      // .catch((error) => {
      //   console.error("Error fetching permissions:", error);
      //   setToastMsg("Facing network issues. Please try again");
      //   setToastType("error");
      //   setShowToast(true);
      //   setButtonLoaders({ ...buttonLoaders, google: false });
      // })
      .catch((err) => {
        const errCode = err.code;
        const errMessage = err.message;
        const email = err.customData.email;
        const credential = OAuthProvider.credentialFromError(err);
        if (errCode === "auth/popup-closed-by-user") {
          setToastMsg("Signin cancelled by the user.");
        } else {
          setToastMsg(errMessage);
        }
        setToastType("error");
        setShowToast(true);
        setButtonLoaders({ ...buttonLoaders, google: false });
      });
  };

  const editPhoneNumber = () => {
    setOTPSent(false);
    setUpdateTimer(0);
    setTime30Sec(null);
    setButtonLoaders({
      ...buttonLoaders,
      google: false,
      signin: false,
      otp: false,
    });
  };

  const inputCustomButton = (
    onClickFunction,
    textColor,
    textValue,
    id = "input-button"
  ) => {
    return (
      <IconButton edge="start" onClick={onClickFunction} id={id}>
        <div className={style.input_button_container}>
          <div
            className={style.vertical_divider}
            style={{ backgroundColor: TEXT_SECONDARY }}
          />
          {typeof textValue === "string" ? (
            <p className={style.input_button_text} style={{ color: textColor }}>
              {textValue}
            </p>
          ) : (
            <div>{textValue}</div>
          )}
        </div>
      </IconButton>
    );
  };

  return (
    <div className={style.container}>
      <Header />
      <div className={style.illustration_parent_container}>
        <div className={style.illustration_container}>
          <img src={I_login} className={style.illustration} />
        </div>
      </div>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"center"}
        width={"90%"}
        className={style.google_container}
      >
        <ToastMessage
          message={toastMsg}
          type={toastType}
          open={showToast}
          setOpen={(v) => setShowToast(v)}
        />
        <GoogleButton
          variant="outlined"
          onClick={() => {
            if (!buttonLoaders.google) signInWithGoogleApp();
          }}
        >
          <GoogleIcon className={style.google_icon} />
          {buttonLoaders.google ? <Loader /> : "Login with Google"}
        </GoogleButton>
      </Stack>
      <div className={style.divider_cont}>
        <DividerWithText />
      </div>
      <Stack
        spacing={2}
        alignItems={"center"}
        justifyContent={"center"}
        width={"90%"}
      >
        <InputField
          id="phone-number"
          type="number"
          placeholder="Enter phone number"
          onChange={phoneNumberUpdate}
          disabled={otpSent}
          value={phoneNumber}
          endButton={inputCustomButton(
            buttonLoaders.otp ? () => {} : otpSent ? editPhoneNumber : getOTP,
            otpSent ? PRIMARY_1_SHADE_2 : PRIMARY_1_SHADE_1,
            buttonLoaders.otp ? (
              <Loader />
            ) : otpSent ? (
              <img src={ic_edit} width={"20px"} height={"20px"} />
            ) : (
              "Get OTP"
            )
          )}
          isError={erroredField === "phone"}
          errorMessage={errorMessage}
        />
        <InputField
          id="otp"
          type="number"
          autocomplete="one-time-code"
          placeholder="Enter OTP"
          disabled={!otpSent}
          onChange={OTPUpdate}
          value={OTP}
          endButton={
            otpSent
              ? inputCustomButton(
                  resendOTP,
                  otpResendTimer ? PRIMARY_1_SHADE_2 : PRIMARY_1_SHADE_1,
                  otpResendTimer ? `Resend${otpResendTimer}` : "Resend"
                )
              : null
          }
          isError={erroredField === "otp"}
          errorMessage={errorMessage}
        />
        <div id="recaptcha-container"></div>
        <div id="g-signin2" style={{ display: "none" }}></div>
        <div id="sign-in-with-phone-button" style={{ display: "none" }}></div>
        <div className={style.login_button_container}>
          <CustomButton
            buttonText={buttonLoaders.signin ? <Loader /> : "Login"}
            variant={"contained"}
            onClick={() => {
              if (!buttonLoaders.signin) loginUser();
            }}
            disabled={!otpSent}
            color={otpSent ? PRIMARY_1_SHADE_1 : "#B2C2D8"}
          />
        </div>
      </Stack>
    </div>
  );
};

export default Login;
