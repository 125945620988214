import React, { useState, useEffect } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ic_down from "../icons/ic_down.png";
import { StyledEngineProvider } from "@mui/material/styles";
import Autocomplete from "@mui/material/Autocomplete";
import "./dropdown.css";
import { ERROR_DARK, ERROR_LIGHT } from "../constants/colors";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import SupportingImageIcon from "./SupportingImageIcon";

const Dropdown = ({
  label = "",
  options = [],
  placeholder = "Select",
  value,
  onChange = () => {},
  errored = false,
  errorMessage,
  required = false,
  hidden = false,
  id = "select",
  freeSolo = false,
  onTextChange = () => {},
  clearIcon = false,
  supportingPhoto = false,
  supportingImage,
  key1 = "dropdown",
  disabled = false,
  onSupportingImageChange = () => {},
  fieldKey,
}) => {
  const [localOptions, setLocalOptions] = useState([]);
  const [localVal, setLocalVal] = useState(value);

  useEffect(() => {
    if (typeof value === "string") {
      if (options && typeof options === "object") {
        try {
          options.map((val) => {
            if (val.code === value || val.value === value) {
              setLocalVal(val);
            }
          });
        } catch (error) {
          try {
            for (const [key, val] of Object.entries(options)) {
              if (val.code === value || val.value === value) {
                setLocalVal(val);
                break;
              }
            }
          } catch (error) {
            console.log(error);
            setLocalVal({ value: value, name: value });
          }
        }
      } else {
        setLocalVal(value);
      }
    } else {
      setLocalVal(value);
    }
  }, [value, options]);

  useEffect(() => {
    var optionsList = [];
    for (const [key, val] of Object.entries(options)) {
      optionsList.push(val);
    }
    setLocalOptions(optionsList);
  }, [options]);

  const downIcon = () => {
    return <img src={ic_down} width={"12px"} style={{ marginRight: "5%" }} />;
  };

  const handleChange = (e, val) => {
    if (val) {
      onChange(val);
    }
  };

  return (
    <StyledEngineProvider injectFirst>
      {hidden ? (
        <></>
      ) : (
        <div className="container">
          {label ? (
            <div style={{ display: "flex", alignItems: "center" }}>
              <p
                className="field-label"
                style={{ color: errored ? ERROR_DARK : null }}
              >
                {label + (required && label ? "*" : "")}
              </p>
              {supportingPhoto ? (
                <SupportingImageIcon
                  supportingImage={supportingImage}
                  key1={key1}
                  onClick={onSupportingImageChange}
                />
              ) : null}
            </div>
          ) : null}
          <Autocomplete
            disabled={disabled}
            disablePortal
            autoHighlight
            id={id}
            freeSolo={freeSolo}
            options={localOptions}
            value={localVal}
            defaultValue={localVal}
            clearIcon={clearIcon}
            getOptionKey={(option) =>
              option.code ? option.code : option.value
            }
            getOptionLabel={(option) =>
              option.name
                ? option.name
                : fieldKey === "rc"
                ? option.rcNumber
                : fieldKey === "chassis"
                ? option.chassisNumber
                : ""
            }
            renderOption={(props, option) => {
              return (
                <Box component="li" {...props}>
                  {option?.name
                    ? option?.name
                    : fieldKey === "rc"
                    ? option.rcNumber
                    : fieldKey === "chassis"
                    ? option.chassisNumber
                    : ""}
                </Box>
              );
            }}
            onChange={(e, newValue) => {
              handleChange(e, newValue);
            }}
            onInputChange={(e) => {
              if (e) {
                onTextChange(e, e.target.value);
              }
            }}
            noOptionsText="No options"
            popupIcon={downIcon()}
            renderInput={(params) => (
              <TextField
                variant="filled"
                {...params}
                label={placeholder}
                sx={{
                  ".MuiFilledInput-root": {
                    backgroundColor: errored ? ERROR_LIGHT : "",
                  },
                  ".MuiInputLabel-root": {
                    color: errored ? ERROR_DARK : "",
                  },
                }}
                inputProps={{
                  ...params.inputProps,
                  // autoComplete: "new-password", // disable autocomplete and autofill
                }}
              />
            )}
          />
          {errorMessage ? (
            <p className="error-label" style={{ color: ERROR_DARK }}>
              {errorMessage}
            </p>
          ) : null}
        </div>
      )}
    </StyledEngineProvider>
  );
};

export default Dropdown;
