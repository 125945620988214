import React from "react";

const HorizontalDivider = () => {
  return (
    <hr
      style={{
        height: "2px",
        backgroundColor: "#f7f8f9",
        border: 0,
        width: "100%",
      }}
    />
  );
};

export default HorizontalDivider;
