import React from "react";
import { Box, Modal } from "@mui/material";
import ic_close from "../icons/ic_close.png";

const FullSizeImageModal = ({ open = false, closeModal = () => {}, image }) => {
  return (
    <Modal
      sx={{
        border: "none",
      }}
      open={open}
      onClose={() => closeModal(!open)}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100vw",
          bgcolor: "white",
          border: "none",
          p: 4,
          boxShadow: "none",
          outline: "none",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={ic_close}
          height={"30px"}
          width={"30px"}
          style={{
            position: "absolute",
            top: "10px",
            right: "10%",
            zIndex: 100,
          }}
          onClick={() => closeModal(!open)}
        />
        <img src={image} style={{ width: "80%" }} />
      </Box>
    </Modal>
  );
};

export default FullSizeImageModal;
