import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

export const appData = createSlice({
  name: "appData",
  initialState,
  reducers: {
    addAppData: (state, action) => {
      return { ...state, ...action.payload };
    },
    resetAppData: (state) => {
      return initialState;
    },
  },
});

export const { addAppData, resetAppData } = appData.actions;

export default appData.reducer;
