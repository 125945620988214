import React from "react";
import ic_close from "../icons/ic_close.png";

import "./topBanner.css";

const TopBanner = ({
  heading = "",
  id = "",
  additionalText = "",
  onClose = () => {},
}) => {
  return (
    <div className="top-banner-container">
      <div className="top-banner-left-container">
        <div className="top-banner-header">{heading}</div>
        <div className="top-banner-subtitle-container">
          <div className="top-banner-subtitle">ID: {id}</div>
          <div className="horizontal-divider" />
          <div className="top-banner-subtitle">{additionalText}</div>
        </div>
      </div>
      <div className="top-banner-right-container" onClick={onClose}>
        <img className="top-banner-button" src={ic_close} />
      </div>
    </div>
  );
};

export default TopBanner;
