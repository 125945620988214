import { Modal } from "@mui/material";
import React from "react";
import Loader from "../components/loader";
import "./singleOptionModal.css";
import { StyledEngineProvider } from "@mui/material/styles";
import CustomOutlineButton from "../components/customOutlineButton";
import CustomButton from "../components/customButton";

const SingleOptionModal = ({
  show = false,
  closeModal = () => {},
  title = "Are you sure?",
  buttonText = "Yes",
  onClick = () => {},
  buttonColor = "",
}) => {
  return (
    <StyledEngineProvider injectFirst>
      <Modal open={show} className="modal" onClose={closeModal}>
        <div className="parent-container">
          <div className="modal-title">{title}</div>
          <div className="buttons-container">
            <CustomButton
              additionalStyles={{ marginLeft: "5%" }}
              buttonText={buttonText}
              color={buttonColor}
              onClick={onClick}
            />
          </div>
        </div>
      </Modal>
    </StyledEngineProvider>
  );
};

export default SingleOptionModal;
