import React, { useState } from "react";
import "./generateFormFromTemplate.css";
import RadioButtons from "./radioButtons";
import DynamicDropdown from "./dynamicDropdown";
import Dropdown from "./dropdown";
import LocationInput from "./locationInput";
import InputField from "./inputField";
import CameraButton from "./cameraButton";
import DateInput from "./dateInput";
import dayjs from "dayjs";
import MultiSelectButton from "./multiSelectButton";

const GenerateFormFromTemplate = ({
  data = [],
  parent = "",
  state = {},
  onChange = () => {},
  handleDamageSelection = () => {},
  errors = {},
  completeData = {},
  inspectionData = {},
  supportingPhotos = {},
  onSupportingImageChange = () => {},
  onLocationClick = () => {},
  requiredFields = {},
  hiddenFields = {},
}) => {
  const [searchURL, setSearchURL] = useState();

  const getCompleteURL = (obj, dt) => {
    var params = getAPIParams(obj.api_params, dt);
    var url = obj.url;
    if (Object.keys(params).length > 0) {
      url = url + "?";
      var ind = 0;
      for (const [key, value] of Object.entries(params)) {
        if (ind !== 0) {
          url = url + "&";
        }
        url = url + key + "=" + value;
        ind++;
      }
      return url;
    } else {
      return url;
    }
  };

  const getAPIParams = (params, dt) => {
    if (!params) {
      return {};
    }
    var api_params = {};
    for (const [key, value] of Object.entries(params)) {
      if (value.type === "fixed") {
        api_params[key] = value.value;
      } else if (value.type === "reference") {
        var objKey = value.value.split(".");
        var thisVal = dt;
        for (var k of objKey) {
          if (thisVal) thisVal = thisVal[k];
        }
        if (thisVal) api_params[key] = thisVal.data;
      }
    }
    return api_params;
  };

  return (
    <div className="form-container">
      {data.map((obj) => {
        if (
          obj.code === "ask_price" &&
          inspectionData.asset_ownership === "electrifi"
        ) {
          return null;
        }
        return (
          <div className="form_item_container" key={parent + "_" + obj.code}>
            {obj.datatype === "radio" ? (
              <RadioButtons
                label={obj.name}
                options={obj.options}
                key1={obj.code}
                selectedOption={
                  state && state[obj.code] && state[obj.code].data
                    ? state[obj.code].data
                    : ""
                }
                disabled={
                  state && state[obj.code] && state[obj.code].disabled
                    ? state[obj.code].disabled
                    : false
                }
                hidden={hiddenFields[obj.code]}
                onChange={(object, val) => onChange(object, val, obj.code)}
                required={requiredFields[obj.code]}
                supportingPhoto={obj.supporting_photo}
                supportingImage={supportingPhotos?.[obj.code]}
                onSupportingImageChange={onSupportingImageChange}
                handleDamageSelection={(top, bottom, left, action, val, name) =>
                  handleDamageSelection(
                    top,
                    bottom,
                    left,
                    action,
                    val,
                    name,
                    obj.code
                  )
                }
                errored={errors[obj.code] ? true : false}
                errorMessage={errors[obj.code]}
              />
            ) : obj.datatype === "dropdown" ? (
              obj.options_api ? (
                <DynamicDropdown
                  placeholder={
                    obj.placeholder + (requiredFields[obj.code] ? "*" : "")
                  }
                  key1={obj.code}
                  supportingPhoto={obj.supporting_photo}
                  supportingImage={supportingPhotos?.[obj.code]}
                  onSupportingImageChange={onSupportingImageChange}
                  onChange={(e) =>
                    onChange(e, e.code ? e.code : e.value, obj.code)
                  }
                  onTextChange={(e, val) => {
                    if (obj.free) onChange(e, val, obj.code);
                  }}
                  value={
                    state && state[obj.code] && state[obj.code].data
                      ? state[obj.code].data
                      : null
                  }
                  url={getCompleteURL(obj.options_api, completeData)}
                  disabled={
                    state && state[obj.code] && state[obj.code].disabled
                      ? state[obj.code].disabled
                      : false
                  }
                  url_mapper={obj.options_api.result_mapping}
                  required={requiredFields[obj.code]}
                  hidden={hiddenFields[obj.code]}
                  errored={errors[obj.code] ? true : false}
                  errorMessage={errors[obj.code]}
                />
              ) : obj.options_search ? (
                <DynamicDropdown
                  placeholder={
                    obj.placeholder + (requiredFields[obj.code] ? "*" : "")
                  }
                  key1={obj.code}
                  freeSolo={true}
                  supportingPhoto={obj.supporting_photo}
                  supportingImage={supportingPhotos?.[obj.code]}
                  onSupportingImageChange={onSupportingImageChange}
                  onChange={(e) =>
                    onChange(e, e.code ? e.code : e.value, obj.code)
                  }
                  onTextChange={(e, val) => {
                    if (obj.free) onChange(e, val, obj.code);
                    var newURL = getCompleteURL(
                      obj.options_search,
                      completeData
                    );
                    if (newURL.includes("?")) {
                      newURL = newURL + "&query=" + val;
                    } else {
                      newURL = newURL + "?query=" + val;
                    }
                    setSearchURL(newURL);
                  }}
                  value={
                    state && state[obj.code] && state[obj.code].data
                      ? state[obj.code].data
                      : null
                  }
                  url={searchURL}
                  disabled={
                    state && state[obj.code] && state[obj.code].disabled
                      ? state[obj.code].disabled
                      : false
                  }
                  url_mapper={obj.options_search.result_mapping}
                  required={requiredFields[obj.code]}
                  hidden={hiddenFields[obj.code]}
                  errored={errors[obj.code] ? true : false}
                  errorMessage={errors[obj.code]}
                />
              ) : (
                <Dropdown
                  options={obj.options}
                  placeholder={obj.name + (requiredFields[obj.code] ? "*" : "")}
                  key1={obj.code}
                  supportingPhoto={obj.supporting_photo}
                  supportingImage={supportingPhotos?.[obj.code]}
                  onSupportingImageChange={onSupportingImageChange}
                  value={
                    state && state[obj.code] && state[obj.code].data
                      ? state[obj.code].data
                      : null
                  }
                  onChange={(e) =>
                    onChange(e, e.code ? e.code : e.value, obj.code)
                  }
                  required={requiredFields[obj.code]}
                  hidden={hiddenFields[obj.code]}
                  errored={errors[obj.code] ? true : false}
                  disabled={
                    state && state[obj.code] && state[obj.code].disabled
                      ? state[obj.code].disabled
                      : false
                  }
                  errorMessage={errors[obj.code]}
                />
              )
            ) : obj.datatype === "multiselect" ? (
              <MultiSelectButton
                label={obj.name}
                options={obj.options}
                key1={obj.code}
                selectedOption={
                  state && state[obj.code] && state[obj.code].data
                    ? state[obj.code].data
                    : ""
                }
                disabled={
                  state && state[obj.code] && state[obj.code].disabled
                    ? state[obj.code].disabled
                    : false
                }
                hidden={hiddenFields[obj.code]}
                onChange={(object, val) =>
                  onChange(object, val, obj.code, "multiselect")
                }
                required={requiredFields[obj.code]}
                supportingPhoto={obj.supporting_photo}
                supportingImage={supportingPhotos?.[obj.code]}
                onSupportingImageChange={onSupportingImageChange}
                handleDamageSelection={(top, bottom, left, action, val, name) =>
                  handleDamageSelection(
                    top,
                    bottom,
                    left,
                    action,
                    val,
                    name,
                    obj.code
                  )
                }
                errored={errors[obj.code] ? true : false}
                errorMessage={errors[obj.code]}
              />
            ) : obj.datatype === "location" ? (
              <LocationInput
                label={obj.name}
                placeholder={obj.placeholder}
                required={requiredFields[obj.code]}
                hidden={hiddenFields[obj.code]}
                key1={obj.code}
                supportingPhoto={obj.supporting_photo}
                supportingImage={supportingPhotos?.[obj.code]}
                onSupportingImageChange={onSupportingImageChange}
                onLocationClick={onLocationClick}
                value={
                  state && state[obj.code] && state[obj.code].data
                    ? state[obj.code].data
                    : { address: "", loc: { lat: null, lon: null } }
                }
                onChange={(val) => onChange(null, val, obj.code)}
                disabled={
                  state && state[obj.code] && state[obj.code].disabled
                    ? state[obj.code].disabled
                    : false
                }
                errored={errors[obj.code] ? true : false}
                errorMessage={errors[obj.code]}
              />
            ) : obj.datatype === "image" ? (
              <CameraButton
                parent={parent}
                buttonText={obj.name}
                key1={obj.code}
                sampleImage={obj.sampleImage}
                required={requiredFields[obj.code]}
                hidden={hiddenFields[obj.code]}
                supportingPhoto={obj.supporting_photo}
                supportingImage={supportingPhotos?.[obj.code]}
                onSupportingImageChange={onSupportingImageChange}
                disabled={
                  state && state[obj.code] && state[obj.code].disabled
                    ? state[obj.code].disabled
                    : false
                }
                onCapture={(e, setLoading) => {
                  if (e?.target?.files)
                    onChange(
                      e,
                      e.target.files[0],
                      obj.code,
                      "image",
                      setLoading
                    );
                }}
                imageSelected={
                  state && state[obj.code] && state[obj.code].data
                    ? typeof state[obj.code].data === "string"
                      ? state[obj.code].data
                      : URL.createObjectURL(state[obj.code].data)
                    : null
                }
                errored={errors[obj.code] ? true : false}
                errorMessage={errors[obj.code]}
              />
            ) : obj.datatype === "date" ? (
              <DateInput
                value={
                  state && state[obj.code] && state[obj.code].data
                    ? state[obj.code].data
                    : null
                }
                key1={obj.code}
                disabled={
                  state && state[obj.code] && state[obj.code].disabled
                    ? state[obj.code].disabled
                    : false
                }
                onChange={(e) => {
                  var date1 = dayjs(e).toDate();
                  var dateStr = "";
                  var date = date1.getDate();
                  if (date < 10) {
                    dateStr = dateStr + "0" + String(date) + "/";
                  } else {
                    dateStr = dateStr + String(date) + "/";
                  }
                  var month = date1.getMonth() + 1;
                  if (month < 10) {
                    dateStr = dateStr + "0" + String(month) + "/";
                  } else {
                    dateStr = dateStr + String(month) + "/";
                  }
                  dateStr = dateStr + String(date1.getFullYear());
                  onChange(e, dateStr, obj.code);
                }}
                isError={errors && errors[obj.code] ? true : false}
                errorMessage={errors[obj.code]}
                id={obj.code}
                placeholder={obj.name + (requiredFields[obj.code] ? "*" : "")}
                pastDate={
                  obj.validation?.future_date
                    ? !obj.validation?.future_date
                    : true
                }
                hidden={hiddenFields[obj.code]}
                supportingPhoto={obj.supporting_photo}
                supportingImage={supportingPhotos?.[obj.code]}
                onSupportingImageChange={onSupportingImageChange}
                futureDate={
                  obj.validation?.past_date ? !obj.validation?.past_date : true
                }
              />
            ) : (
              <div key={obj.code} className="form-input-field">
                <InputField
                  capital={
                    obj.code === "rc_number" || obj.code === "chassis_no"
                      ? true
                      : false
                  }
                  placeholder={obj.name + (requiredFields[obj.code] ? "*" : "")}
                  hidden={hiddenFields[obj.code]}
                  key1={obj.code}
                  type={obj.datatype}
                  supportingPhoto={obj.supporting_photo}
                  supportingImage={supportingPhotos?.[obj.code]}
                  onSupportingImageChange={onSupportingImageChange}
                  value={
                    state && state[obj.code] && state[obj.code].data
                      ? state[obj.code].data
                      : ""
                  }
                  onChange={(e) => onChange(e, e.target.value, obj.code)}
                  isError={errors[obj.code] ? true : false}
                  errorMessage={errors[obj.code]}
                  disabled={
                    state && state[obj.code] && state[obj.code].disabled
                      ? state[obj.code].disabled
                      : // : inspectionData.asset_uid_type === "rc" &&
                        //   obj.code === "rc_number"
                        // ? true
                        // : inspectionData.asset_uid_type === "chassis" &&
                        //   obj.code === "chassis_no"
                        // ? true
                        false
                  }
                />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default GenerateFormFromTemplate;
