import { Button } from "@mui/material";
import React from "react";
import {
  PRIMARY_1_SHADE_1,
  PRIMARY_1_SHADE_2,
  PRIMARY_BUTTON_TAPPED,
  WHITE,
} from "../constants/colors";
import "./customButton.css";
import { StyledEngineProvider } from "@mui/material/styles";

const CustomButton = ({
  buttonText = "Submit",
  color = PRIMARY_1_SHADE_1,
  tappedColor = PRIMARY_BUTTON_TAPPED,
  textColor = WHITE,
  tappedTextColor = WHITE,
  borderColor = PRIMARY_1_SHADE_1,
  tappedBorderColor = PRIMARY_BUTTON_TAPPED,
  onClick = () => {},
  disabled = false,
  textSize = "medium",
  additionalStyles = {},
  startIcon,
  endIcon,
}) => {
  return (
    <StyledEngineProvider injectFirst>
      {startIcon ? { startIcon } : null}
      <Button
        fullWidth
        variant={"contained"}
        sx={{
          backgroundColor: color,
          color: textColor,
          fontSize: textSize,
          transition: "none",
          borderColor: borderColor,
          ":focus": {
            backgroundColor: tappedColor,
            borderColor: tappedBorderColor,
            color: tappedTextColor,
          },
          "&.MuiButtonBase-root.Mui-disabled": {
            backgroundColor: color,
            color: textColor,
            fontSize: textSize,
            transition: "none",
            borderColor: borderColor,
            ":focus": {
              color: tappedTextColor,
            },
          },
          ...additionalStyles,
        }}
        onClick={onClick}
        disabled={disabled}
      >
        {buttonText}
      </Button>
      {endIcon ? { endIcon } : null}
    </StyledEngineProvider>
  );
};

export default CustomButton;
