import React, { useEffect, useRef, useState } from "react";
import "./SupportingImageIcon.css";
import ic_camera from "../icons/ic_camera.png";
import ic_error from "../icons/ic_error.png";
import { useSelector, useDispatch } from "react-redux";
import { addAppData } from "../features/appData/appDataSlice";
import { auth } from "../firebase/config";
import { BackendAPI } from "../api";
import Loader from "./loader";
import FullSizeImageModal from "../modals/fullSizeImageModal";
import ic_edit from "../icons/ic_edit.png";
import PopupModal from "../modals/popupModal";

const SupportingImageIcon = ({
  supportingImage,
  onClick = () => {},
  key1 = "support",
}) => {
  const selectedImage = useSelector(
    (st) => st.appData?.["supporting_image_" + key1]
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openImage, setOpenImage] = useState();
  const [showPickerModal, setShowPickerModal] = useState(false);
  const [clickPosition, setClickPosition] = useState({ X: 0, Y: 0 });
  const [pickerModalPositionY, setPickerModalPositionY] = useState("bottom");
  const [pickerModalPositionX, setPickerModalPositionX] = useState("right");
  const [pickerModalStart, setPickerModalStart] = useState(20);
  const [cameraContainerRefPos, setCameraContainerRefPos] = useState({
    start: 0,
    end: 0,
  });
  const dispatch = useDispatch();
  const cameraRef = useRef();
  const fileExplorerRef = useRef();
  const pickerRef = useRef();

  useEffect(() => {
    var listener = document.addEventListener("click", updateClickedPos);

    return () => {
      document.removeEventListener("click", updateClickedPos);
    };
  }, []);

  useEffect(() => {
    if (clickPosition.Y + 180 >= window.outerHeight) {
      setPickerModalPositionY("top");
      setPickerModalStart(clickPosition.Y - 120);
    } else {
      setPickerModalPositionY("bottom");
      setPickerModalStart(clickPosition.Y + 20);
    }
    if (
      clickPosition.X + 140 >= window.outerWidth &&
      pickerModalPositionX === "right"
    ) {
      setPickerModalPositionX("left");
    } else if (pickerModalPositionX === "left") {
      setPickerModalPositionX("right");
    }
    setCameraContainerRefPos({ start: clickPosition.Y, end: clickPosition.Y });
  }, [clickPosition]);

  const updateClickedPos = (e) => {
    if (e) {
      setClickPosition({ X: e.clientX, Y: e.clientY });
    }
  };

  useEffect(() => {
    setLoading(true);
    getImageLink(supportingImage, key1);
  }, [supportingImage]);

  const getImageLink = async (link, code) => {
    if (!selectedImage) {
      var key = "supporting_image_" + code;
      if (link) {
        if (String(link).startsWith("http")) {
          setLoading(false);
          setError(false);
          dispatch(addAppData({ [key]: link }));
        } else {
          setLoading(true);
          var data1 = {};
          try {
            var token = await auth.currentUser.getIdToken();
            var configs = {
              headers: {
                Authorization: `bearer ${token}`,
              },
            };
            data1[key] = "loading";
            dispatch(addAppData(data1));
            var resp = await BackendAPI.get(
              `/inspection/image/get_url?path=${link}`,
              configs
            );
            if (resp.status === 200) {
              data1[key] = resp.data.data.url;
              dispatch(addAppData(data1));
            } else {
              data1[key] = null;
              dispatch(addAppData(data1));
              setLoading(false);
              setError(false);
              return null;
            }
            setError(false);
            setLoading(false);
          } catch (error) {
            console.log(error);
            data1[key] = null;
            dispatch(addAppData(data1));
            setError(false);
            setLoading(false);
          }
        }
      } else {
        setLoading(false);
        setError(false);
        dispatch(addAppData({ [key]: null }));
      }
    } else {
      setLoading(false);
    }
  };

  const openCamera = () => {
    setShowPickerModal(false);
    if (cameraRef.current) {
      cameraRef.current.click();
    }
  };

  const openFileExplorer = () => {
    setShowPickerModal(false);
    if (fileExplorerRef.current) {
      fileExplorerRef.current.click();
    }
  };

  const setPhoto = (e) => {
    setShowPickerModal(false);
    if (e.target.files && e.target.files.length > 0) {
      var key = "supporting_image_" + key1;
      dispatch(addAppData({ [key]: null }));
      onClick(e, key1, setLoading, setError);
      setError(false);
      setLoading(true);
    }
  };

  const pickerTypeSelector = () => {
    return (
      <div className="picker-type-container" ref={pickerRef}>
        <div className="picker-camera" onClick={openCamera}>
          Camera
        </div>
        <div className="picker-file-selector" onClick={openFileExplorer}>
          File Selector
        </div>
      </div>
    );
  };

  const openPickerModal = () => {
    setCameraContainerRefPos({
      start: clickPosition.Y,
      end: clickPosition.Y,
    });
    setShowPickerModal(true);
  };

  return (
    <div style={{ marginLeft: "10px" }}>
      <FullSizeImageModal
        open={openModal}
        closeModal={setOpenModal}
        image={openImage}
      />
      <PopupModal
        show={showPickerModal}
        containerY={cameraContainerRefPos}
        pointerX={clickPosition.X}
        positionX={pickerModalPositionX}
        children={pickerTypeSelector()}
        containerStart={pickerModalStart}
        handleClose={() => setShowPickerModal(false)}
        positionY={pickerModalPositionY}
      />
      {loading ? (
        <Loader size="24px" />
      ) : error ? (
        <img
          src={ic_error}
          height={"24px"}
          width={"24px"}
          onClick={openPickerModal}
        />
      ) : selectedImage ? (
        <>
          <img
            src={selectedImage}
            height={"24px"}
            width={"24px"}
            onClick={() => {
              setOpenImage(selectedImage);
              setOpenModal(true);
            }}
          />
          <img
            src={ic_edit}
            height={"24px"}
            width={"24px"}
            onClick={openPickerModal}
            style={{ marginLeft: "10px" }}
          />
        </>
      ) : (
        <img
          src={ic_camera}
          height={"24px"}
          width={"24px"}
          onClick={openPickerModal}
        />
      )}
      <input
        accept="image/*"
        style={{ display: "none" }}
        type="file"
        ref={cameraRef}
        capture="environment"
        onChange={setPhoto}
      />
      <input
        accept="image/*"
        style={{ display: "none" }}
        type="file"
        ref={fileExplorerRef}
        onChange={setPhoto}
      />
    </div>
  );
};

export default SupportingImageIcon;
