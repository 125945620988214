import React from "react";
import { ERROR_DARK } from "../constants/colors";
import CustomTextButton from "./customTextButton";
import { auth } from "../firebase/config";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { logoutUser } from "../features/user/userSlice";
import { clearDetails } from "../features/inspection/inspectionSlice";
import { resetAppData } from "../features/appData/appDataSlice";

const LogoutButton = () => {
  const [cookie, setCookie, deleteCookie] = useCookies(["electrifi-mobility"]);
  const navigation = useNavigate();
  const dispatch = useDispatch();

  const logout = () => {
    deleteCookie("uid");
    deleteCookie("signin-status");
    deleteCookie("name");
    dispatch(clearDetails());
    dispatch(resetAppData());
    dispatch(logoutUser());
    if (auth && auth.currentUser) {
      auth.signOut();
    }
    navigation("/login");
  };

  return (
    <div
      style={{
        width: "30%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      onClick={logout}
    >
      <CustomTextButton
        buttonText="Logout"
        textColor={ERROR_DARK}
        color=""
        tappedColor=""
        textSize="x-small"
        borderColor=""
        tappedBorderColor=""
      />
    </div>
  );
};

export default LogoutButton;
