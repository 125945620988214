import React from "react";
import { StyledEngineProvider } from "@mui/material/styles";
import { Button } from "@mui/material";
import {
  BLACK,
  GREY_BG,
  PRIMARY_1_SHADE_1,
  PRIMARY_BUTTON_TAPPED,
  TEXT_HEADING,
  WHITE,
} from "../constants/colors";
import { styled } from "@mui/material/styles";
import "./customOutlineButton.css";

const CustomOutlineButton = ({
  buttonText = "Submit",
  color = "",
  tappedColor = GREY_BG,
  textColor = TEXT_HEADING,
  borderColor = PRIMARY_1_SHADE_1,
  tappedBorderColor = PRIMARY_BUTTON_TAPPED,
  onClick = () => {},
  disabled = false,
  textSize = "medium",
  additionalStyles = {},
  fontSize = "medium",
  startIcon,
  endIcon,
}) => {
  const BootstrapButton = styled(Button)({
    boxShadow: "none",
    textTransform: "none",
    fontSize: fontSize,
    outline: 0,
    padding: "10px 0 10px 0",
    border: "1px solid",
    backgroundColor: color,
    color: textColor,
    borderColor: borderColor,
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      backgroundColor: tappedColor,
      borderColor: tappedBorderColor,
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: tappedColor,
      borderColor: tappedBorderColor,
    },
    "&:focus": {},
  });

  return (
    <StyledEngineProvider injectFirst>
      <div className="custom-outline-button-container" onClick={onClick}>
        {startIcon}
        <BootstrapButton fullWidth variant="outlined">
          {buttonText}
        </BootstrapButton>
        {endIcon}
      </div>
    </StyledEngineProvider>
  );
};

export default CustomOutlineButton;
