import {
  checkFutureDate,
  checkPastDate,
  datatypeValidator,
  getDependencyOverrides,
  limitMaximumValue,
  limitMinimumValue,
  required,
} from "./validationFunctions";

const getDate = (val) => {
  if (!val) return "Invalid Date";
  var values = val.split("/");
  if (values.length !== 3) {
    return "Invalid Date";
  }
  return new Date(values[2], Number(values[1]) - 1, values[0]);
};

const handleValidations = (data, validation, completeData, inspectionData) => {
  try {
    if (!validation) {
      return {
        success: true,
        required: false,
        hidden: false,
      };
    }
    if (validation["dependency"]) {
      var overrides = getDependencyOverrides(
        validation["dependency"],
        completeData,
        inspectionData
      );
      validation = { ...validation, ...overrides };
      delete validation["dependency"];
    }
    var resp_obj = { required: validation.required, hidden: validation.hidden };
    var require = validation.required ? validation.required : false;
    var hidden = validation.hidden ? validation.hidden : false;
    for (const [index, key] of Object.entries(validation)) {
      if (index === "hidden") {
        hidden = key;
        return {
          ...resp_obj,
          success: true,
        };
      } else if (index === "required") {
        require = key;
        if (!required(data, key)) {
          return {
            ...resp_obj,
            success: false,
            message: "This field is required",
          };
        }
      } else if (index === "past_date") {
        var condCheck = checkPastDate(getDate(data), new Date());
        if ((data && !condCheck) || (!require && data && !condCheck)) {
          return {
            ...resp_obj,
            success: false,
            message: "The date should be from the past",
          };
        }
      } else if (index === "future_date") {
        var condCheck = checkFutureDate(getDate(data), new Date());
        if ((data && !condCheck) || (!require && data && !condCheck)) {
          return {
            ...resp_obj,
            success: false,
            message: "The date should be from the future",
          };
        }
      } else if (index === "date_after") {
        var fields = key.split(".");
        var date = completeData;
        for (var i of fields) {
          if (date) {
            date = date[i];
          }
        }
        var condCheck = checkFutureDate(getDate(data), getDate(date));
        if ((data && !condCheck) || (!require && data && !condCheck)) {
          return {
            ...resp_obj,
            success: false,
            message: "Please enter a valid value for this field",
          };
        }
      } else if (index === "date_before") {
        var fields = key.split(".");
        var date = completeData;
        for (var i of fields) {
          if (date) {
            date = date[i];
          }
        }
        var condCheck = checkPastDate(getDate(data), getDate(date));
        if ((data && !condCheck) || (!require && data && !condCheck)) {
          return {
            ...resp_obj,
            success: false,
            message: "Please enter a valid value for this field",
          };
        }
      } else if (index === "minimum_value") {
        var condCheck = limitMinimumValue(data, key);
        if ((data && !condCheck) || (!require && data && !condCheck)) {
          return {
            ...resp_obj,
            success: false,
            message: "Please enter a value >= " + String(key),
          };
        }
      } else if (index === "maximum_value") {
        var condCheck = limitMaximumValue(data, key);
        if ((data && !condCheck) || (!require && data && !condCheck)) {
          return {
            ...resp_obj,
            success: false,
            message: "Please enter a value <= " + String(key),
          };
        }
      } else if (index === "type") {
        var condCheck = datatypeValidator(data, key);
        if (data && !condCheck) {
          return {
            ...resp_obj,
            success: false,
            message: `Please enter a ${key} value`,
          };
        }
      }
    }
    return { ...resp_obj, success: true };
  } catch (error) {
    console.log(error);
    return true;
  }
};

export default handleValidations;
