import React, { useEffect, useRef, useState } from "react";
import "./damageExtentMenu.css";
import { Modal } from "@mui/material";
import { PRIMARY_1_SHADE_1, TEXT_HEADING } from "../constants/colors";

const DamageExtentMenu = ({
  show = false,
  containerY = { start: 0, end: 0 },
  pointerX = 0,
  handleClose = () => {},
  handleSelection = () => {},
  position = "bottom",
  dataMap = {},
}) => {
  const [containerStart, setContainerStart] = useState(
    Number(containerY.end) + 20
  );
  const containerRef = useRef();

  useEffect(() => {
    if (containerRef.current && position === "top") {
      var positions = containerRef.current.getBoundingClientRect();
      var height = positions.height;
      setContainerStart(containerY.start - height + 20);
    } else {
      setContainerStart(Number(containerY.end) + 20);
    }
  }, [containerY]);

  useEffect(() => {
    if (containerRef.current) {
      var positions = containerRef.current.getBoundingClientRect();
      var height = positions.height;
      if (position === "top") {
        setContainerStart(containerY.start - height + 20);
      }
    }
  }, [containerRef.current]);

  const getDataFromMap = () => {
    var optionsList = [];
    var index = 0;
    var length = Object.keys(dataMap).length;
    var width = length <= 3 && length !== 0 ? `${100 / length}%` : "33.33%";
    for (const [key, value] of Object.entries(dataMap)) {
      if (index === 0) {
        optionsList.push(
          <div
            key={value?.value}
            className="lowest-section options-section"
            style={{ width: width }}
            onClick={() => handleSelection(value.value)}
          >
            <div
              className="low-bar damage-bar"
              style={{
                backgroundColor: value?.color
                  ? value?.color
                  : PRIMARY_1_SHADE_1,
              }}
            />
            <p
              className="text-field"
              style={{ color: value?.color ? value?.color : TEXT_HEADING }}
            >
              {value?.name}
            </p>
          </div>
        );
      } else if (index === length - 1) {
        optionsList.push(
          <div
            key={value?.value}
            className="highest-section options-section"
            style={{ width: width }}
            onClick={() => handleSelection(value.value)}
          >
            <div
              className="high-bar damage-bar"
              style={{
                backgroundColor: value?.color
                  ? value?.color
                  : PRIMARY_1_SHADE_1,
              }}
            />
            <p
              className="text-field"
              style={{ color: value?.color ? value?.color : TEXT_HEADING }}
            >
              {value?.name}
            </p>
          </div>
        );
      } else {
        optionsList.push(
          <div
            key={value?.value}
            className="middle-section options-section"
            style={{ width: width }}
            onClick={() => handleSelection(value.value)}
          >
            <div
              className="medium-bar damage-bar"
              style={{
                backgroundColor: value?.color
                  ? value?.color
                  : PRIMARY_1_SHADE_1,
              }}
            />
            <p
              className="text-field"
              style={{ color: value?.color ? value?.color : TEXT_HEADING }}
            >
              {value?.name}
            </p>
          </div>
        );
      }
      index++;
    }
    return optionsList;
  };

  return (
    <Modal
      open={show}
      style={{ height: "100%", width: "100%" }}
      sx={{ backgroundColor: "transparent" }}
      onClose={handleClose}
    >
      <div
        ref={containerRef}
        className="damage-container"
        style={{
          top: containerStart,
          display: show ? "flex" : "none",
        }}
      >
        <div
          className="damage-square"
          style={{
            top: position === "bottom" ? "-10px" : null,
            left: pointerX,
            bottom: position === "top" ? "-10px" : null,
          }}
        />
        <div className="damage-title-container">
          <p className="damage-title">Select Extent</p>
        </div>
        <div className="damage-selector-container">{getDataFromMap()}</div>
      </div>
    </Modal>
  );
};

export default DamageExtentMenu;
