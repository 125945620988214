import React from 'react';
import './header.css';
import logo from '../icons/logo.png';

const Header = ({
    headerDescription = 'Inspection App',
    endItem,
    width = '80%',
    marginHorizontal = '10%',
    marginTop = '10%',
}) => {
    return (
        <div
            className={'header_parent_container'}
            style={{
                width: width,
                marginLeft: marginHorizontal,
                marginRight: marginHorizontal,
                marginTop: marginTop,
            }}>
            <div className={'header_container'}>
                <img
                    src={logo}
                    className={'logo'}
                    height={
                        (window.innerWidth || window.outerWidth) *
                        0.3 *
                        (35 / 121.62)
                    }
                />
                <div className={'header_divider_container'}>
                    <div
                        className={'header_divider'}
                        style={{
                            height: window.innerWidth * 0.3 * (35 / 121.62),
                        }}
                    />
                </div>
                <p className={'header_description'}>
                    {headerDescription.replace(' ', '\n')}
                </p>
            </div>
            {endItem ? {...endItem} : null}
        </div>
    );
};

export default Header;
