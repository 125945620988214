import React from "react";
import IconButton from "@mui/material/IconButton";
import ic_location from "../icons/ic_location.png";
import InputField from "../components/inputField.js";

import "./locationInput.css";
import { ERROR_DARK } from "../constants/colors.js";
import SupportingImageIcon from "./SupportingImageIcon.js";

const LocationInput = ({
  label = "",
  placeholder = "",
  value = { address: "", loc: { lat: null, lon: null } },
  onChange = () => {},
  errored = false,
  errorMessage,
  required = false,
  supportingPhoto = false,
  supportingImage,
  hidden = false,
  key1 = "location-input",
  disabled = false,
  onSupportingImageChange = () => {},
  onLocationClick = () => {},
}) => {
  const inputCustomButton = () => {
    return (
      <IconButton edge="start" onClick={onLocationClick}>
        <div>
          <img src={ic_location} height={"20px"} width={"20px"} />
        </div>
      </IconButton>
    );
  };

  const updateUserLocation = (e) => {
    if (e) {
      var newVal = e.target.value;
      var newLocation = { ...value, address: newVal };
      onChange(newLocation);
    }
  };

  return (
    <div className="container">
      {hidden ? (
        <></>
      ) : (
        <>
          <div style={{ display: "flex", alignItems: "center" }}>
            <p
              className="field-label"
              style={{ color: errored ? ERROR_DARK : null }}
            >
              {label} {required ? " *" : ""}
            </p>
            {supportingPhoto ? (
              <SupportingImageIcon
                supportingImage={supportingImage}
                key1={key1}
                onClick={() => {
                  if (!disabled) onSupportingImageChange();
                }}
              />
            ) : null}
          </div>
          <InputField
            placeholder={placeholder}
            type="text"
            endButton={inputCustomButton()}
            value={value.address}
            onChange={updateUserLocation}
            isError={errored}
            disabled={disabled}
            errorMessage={errorMessage}
          />
        </>
      )}
    </div>
  );
};

export default LocationInput;
