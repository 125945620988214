import React, { useRef } from "react";
import "./radioButtons.css";
import CustomButton from "./customButton";
import {
  BLACK,
  ERROR_DARK,
  GREY_SECONDARY_BACKGROUND,
  PRIMARY_1_SHADE_1,
  PRIMARY_1_SHADE_2,
  PRIMARY_1_SHADE_3,
  TEXT_HEADING,
  WHITE,
} from "../constants/colors";
import { Grid } from "@mui/material";
import { StyledEngineProvider } from "@mui/material/styles";
import SupportingImageIcon from "./SupportingImageIcon";

const RadioButtons = ({
  label = "",
  options = [],
  selectedOption = "",
  onChange = () => {},
  errored = false,
  errorMessage,
  required = false,
  disabled = false,
  supportingPhoto = false,
  supportingImage,
  handleDamageSelection = () => {},
  onSupportingImageChange = () => {},
  key1 = "radio-button",
  hidden = false,
}) => {
  const damagedRef = useRef();
  const containerRef = useRef();

  const handleClick = (val, name, type = "") => {
    if (type === "map_options") {
      if (damagedRef.current && containerRef.current) {
        var positions = damagedRef.current.getBoundingClientRect();
        var positions2 = containerRef.current.getBoundingClientRect();
        handleDamageSelection(
          positions2.top,
          positions2.bottom,
          positions.x + positions.width / 2 - 10,
          true,
          val,
          name
        );
      }
    } else {
      handleDamageSelection(null, null, null, false);
      onChange(val, name);
    }
  };

  const getOptions = () => {
    var optionsList = [];
    for (const [key, val] of Object.entries(options)) {
      if (val?.value) {
        optionsList.push(
          <Grid
            item
            key={val.value}
            ref={val.value === "damaged" ? damagedRef : null}
          >
            <CustomButton
              buttonText={val.name}
              color={
                selectedOption === val.value
                  ? PRIMARY_1_SHADE_1
                  : val.value === "damaged" &&
                    selectedOption.split("_")[0] === val.value
                  ? selectedOption.split("_")[1] === "low"
                    ? "#00b8b7"
                    : selectedOption.split("_")[1] === "medium"
                    ? "#ffb629"
                    : "#ef3c3c"
                  : GREY_SECONDARY_BACKGROUND
              }
              textColor={
                selectedOption === val.value ||
                (val.value === "damaged" &&
                  selectedOption.split("_")[0] === val.value)
                  ? WHITE
                  : TEXT_HEADING
              }
              tappedTextColor={WHITE}
              onClick={() => {
                if (!disabled) handleClick(val, val.value);
              }}
              tappedColor={
                selectedOption === val.value
                  ? PRIMARY_1_SHADE_1
                  : val.value === "damaged" &&
                    selectedOption.split("_")[0] === val.value
                  ? selectedOption.split("_")[1] === "low"
                    ? "#00b8b7"
                    : selectedOption.split("_")[1] === "medium"
                    ? "#ffb629"
                    : "#ef3c3c"
                  : PRIMARY_1_SHADE_2
              }
              disabled={val.disabled || disabled}
              textSize="xx-small"
              additionalStyles={{ boxShadow: "none" }}
            />
          </Grid>
        );
      } else if (val?.value_map) {
        var mapObj = null;
        for (const [mapKey, mapValue] of Object.entries(val.value_map)) {
          if (mapValue?.value === selectedOption) {
            mapObj = mapValue;
            break;
          }
        }
        optionsList.push(
          <Grid item key={val.name} ref={damagedRef}>
            <CustomButton
              buttonText={val.name}
              color={
                mapObj
                  ? mapObj.color
                    ? mapObj.color
                    : PRIMARY_1_SHADE_1
                  : GREY_SECONDARY_BACKGROUND
              }
              textColor={mapObj ? WHITE : TEXT_HEADING}
              onClick={() => {
                if (!disabled) handleClick(val, val.value_map, "map_options");
              }}
              tappedTextColor={WHITE}
              tappedColor={
                mapObj
                  ? mapObj.color
                    ? mapObj.color
                    : PRIMARY_1_SHADE_1
                  : PRIMARY_1_SHADE_2
              }
              disabled={val.disabled || disabled}
              textSize="xx-small"
              additionalStyles={{ boxShadow: "none" }}
            />
          </Grid>
        );
      }
    }
    return optionsList;
  };

  return (
    <StyledEngineProvider injectFirst>
      {hidden ? (
        <></>
      ) : (
        <Grid
          container
          spacing={2}
          direction={"column"}
          className="container"
          ref={containerRef}
        >
          <Grid style={{ display: "flex", alignItems: "center" }}>
            <p
              className="field-label"
              style={{ color: errored ? ERROR_DARK : null }}
            >
              {label}
              {required ? "*" : ""}
            </p>
            {supportingPhoto ? (
              <SupportingImageIcon
                supportingImage={supportingImage}
                key1={key1}
                onClick={onSupportingImageChange}
              />
            ) : null}
          </Grid>
          <Grid container spacing={1} direction={"row"}>
            {getOptions()}
          </Grid>
          {errorMessage ? (
            <Grid>
              <p className="error-label" style={{ color: ERROR_DARK }}>
                {errorMessage}
              </p>
            </Grid>
          ) : null}
        </Grid>
      )}
    </StyledEngineProvider>
  );
};

export default RadioButtons;
