import React from "react";
import "./dividerWithText.css";
import { GREY_STROKE, TEXT_SECONDARY } from "../constants/colors";

const DividerWithText = ({ text = "OR" }) => {
  return (
    <div className={"divider_container"}>
      <div className={"divider"} style={{ backgroundColor: GREY_STROKE }} />
      <div className={"divider_text"} style={{ color: TEXT_SECONDARY }}>
        {text}
      </div>
      <div className={"divider"} style={{ backgroundColor: GREY_STROKE }} />
    </div>
  );
};

export default DividerWithText;
