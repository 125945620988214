import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  statuses: {},
  previousData: {},
  sections: {},
};

export const InspectionSlice = createSlice({
  name: "inspection",
  initialState,
  reducers: {
    updateDetails: (state, action) => {
      state = { ...state, ...action.payload };
      return state;
    },
    updateFormDetails: (state, action) => {
      var data = action.payload;
      if (data.code && data.data && data.section_identifier) {
        var sections = state.sections;
        sections[data.code] = data.data;
        sections["section_identifier"] = data.section_identifier;
      }
    },
    continuePreviousDetails: (state, action) => {
      var data = { ...action.payload };
      data["_id"] = null;
      delete data["_id"];
      var sections = data.sections;
      var updated_sections = {};
      var statuses = { ...state.statuses };
      for (const [key, value] of Object.entries(sections)) {
        if (key === "section_identifier") {
          updated_sections[key] = value
        } else if (value) {
          updated_sections[key] = {};
          for (const [key1, value1] of Object.entries(value)) {
            if (key1 === "state") {
              statuses[key] = {
                ...statuses[key],
                status: value1.data === "completed" ? true : false,
              };
            } else {
              updated_sections[key][key1] = value1;
            }
          }
        } else {
          statuses[key] = {
            ...statuses[key],
            status: false,
          };
          updated_sections[key] = value;
        }
        if (!statuses[key]) {
          statuses[key] = {
            ...statuses[key],
            status: false,
          };
        }
      }
      data["sections"] = updated_sections;
      state = { ...state, ...data, statuses: statuses };
      return state;
    },
    updatePreviousData: (state, action) => {
      if (action.payload) {
        var sections = action.payload.sections;
        var newSections = {};
        for (const [key, value] of Object.entries(sections)) {
          var newVal = {};
          if (key === "section_identifier") {
            newSections[key] = value
          } else if (key === "photos" || key === "documents") {
            if (value) {
              for (const [key1, value1] of Object.entries(value)) {
                newVal[key1] = {
                  data: value1,
                  include: false,
                };
              }
            }
            newSections[key] = newVal;
          } else {
            if (value) {
              for (const [key1, value1] of Object.entries(value)) {
                newVal[key1] = {
                  data: value1,
                  include: true,
                };
              }
            }
            newSections[key] = newVal;
          }
        }
        state.previousData = { ...action.payload, sections: newSections };
      }
    },
    updateStatuses: (state, action) => {
      var data = action.payload;
      var statuses = state.statuses;
      statuses[data.code] = data.status;
    },
    clearDetails: (state) => {
      state = initialState;
      return state;
    },
  },
});

export const {
  updateDetails,
  clearDetails,
  updateStatuses,
  updateFormDetails,
  updatePreviousData,
  continuePreviousDetails,
} = InspectionSlice.actions;

export default InspectionSlice.reducer;
