import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loggedIn: false,
  name: null,
  email: null,
  phoneNumber: null,
  uid: null,
  token: null,
  tokenHash: null,
};

export const UserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateUser: (state, action) => {
      state = { ...state, ...action.payload };
      return state;
    },
    logoutUser: (state) => {
      state = initialState;
      return state;
    },
  },
});

export const { updateUser, logoutUser } = UserSlice.actions;

export default UserSlice.reducer;
