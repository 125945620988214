import React, { useState, useEffect } from "react";
import Dropdown from "./dropdown";
import { useDispatch, useSelector } from "react-redux";
import { addApiData } from "../features/apiData/apiDataSlice";
import { BackendAPI } from "../api";
import { auth } from "../firebase/config";

const DynamicDropdown = ({
  label = "",
  placeholder = "Select",
  onChange = () => {},
  onTextChange = () => {},
  url = "",
  errored = false,
  errorMessage,
  required = false,
  value = "",
  url_mapper,
  freeSolo = false,
  clearIcon = false,
  supportingPhoto = false,
  supportingImage,
  key1 = "dynamic-dropdown",
  disabled = false,
  hidden = false,
  onSupportingImageChange = () => {},
  fieldKey,
}) => {
  const options = useSelector((st) => st.apiData[url]);
  const [selectedOption, setSelectedOption] = useState(value);
  const dispatch = useDispatch();

  useEffect(() => {
    if (value === null || value === "") setSelectedOption(value);
  }, [value]);

  useEffect(() => {
    if (!options && auth.currentUser) {
      getOptions();
    }
  }, [url, auth.currentUser]);

  const getOptions = async () => {
    try {
      if (!url) return;
      var token = await auth.currentUser.getIdToken();
      var configs = {
        headers: {
          Authorization: `bearer ${token}`,
        },
      };
      var resp = await BackendAPI.get(url, configs);
      var data = await resp.data;
      if (data?.data?.details?.results) {
        var newData = data.data.details.results;
        var finalData = {};
        if (url_mapper) {
          for (const [key, value] of Object.entries(newData)) {
            var dt = {};
            for (const [key1, value1] of Object.entries(value)) {
              if (url_mapper[key1]) {
                dt[url_mapper[key1]] = value1;
              } else {
                dt[key1] = value1;
              }
            }
            finalData[key] = dt;
          }
        }
        if (Object.keys(finalData).length === 0) {
          finalData = newData;
        }
        dispatch(addApiData({ [url]: finalData }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const changeSelectedOption = (e) => {
    if (e.code) {
      setSelectedOption(e);
    } else {
      setSelectedOption(e);
    }
    onChange(e);
  };

  return (
    <div>
      {hidden ? (
        <></>
      ) : (
        <Dropdown
          label={label}
          placeholder={placeholder}
          onChange={changeSelectedOption}
          options={options ? options : {}}
          value={selectedOption}
          errorMessage={errorMessage}
          errored={errored}
          required={required}
          onTextChange={onTextChange}
          freeSolo={freeSolo}
          clearIcon={clearIcon}
          key1={key1}
          disabled={disabled}
          supportingPhoto={supportingPhoto}
          supportingImage={supportingImage}
          onSupportingImageChange={onSupportingImageChange}
          fieldKey={fieldKey}
        />
      )}
    </div>
  );
};

export default DynamicDropdown;
