import React from "react";
import ic_loading from "../icons/ic_loading.png";
import "./loader.css";

const Loader = ({ size = "20px" }) => {
  return (
    <div className="loader">
      <img src={ic_loading} width={size} height={size} />
    </div>
  );
};

export default Loader;
