import React, { useEffect, useRef, useState } from "react";
import "./popupModal.css";
import { Modal } from "@mui/material";
import { StyledEngineProvider } from "@mui/material/styles";

const PopupModal = ({
  show = false,
  containerY = { start: 0, end: 0 },
  pointerX = 0,
  handleClose = () => {},
  positionY = "bottom",
  positionX = "right",
  containerStart = 20,
  children,
}) => {
  return (
    <StyledEngineProvider injectFirst>
      <Modal
        open={show}
        style={{ height: "100%", width: "100%" }}
        sx={{
          backgroundColor: "transparent",
          ".MuiBackdrop-root": {
            backgroundColor: "transparent",
          },
        }}
        onClose={handleClose}
        id="modal-popup"
      >
        <div
          className="damage-container-popup"
          style={{
            top: containerStart,
            display: show ? "flex" : "none",
          }}
        >
          <div
            style={{
              position: "absolute",
              left: positionX === "right" ? pointerX - 30 : pointerX - 140,
            }}
          >
            {children}
            <div
              className="damage-square-popup"
              style={{
                top: positionY === "bottom" ? "-10px" : null,
                left: positionX === "right" ? 30 : 110,
                bottom: positionY === "top" ? "-10px" : null,
                borderLeft: positionY === "bottom" ? "0.5px solid #44485b" : 0,
                borderTop: positionY === "bottom" ? "0.5px solid #44485b" : 0,
                borderBottom: positionY === "top" ? "0.5px solid #44485b" : 0,
                borderRight: positionY === "top" ? "0.5px solid #44485b" : 0,
              }}
            />
          </div>
        </div>
      </Modal>
    </StyledEngineProvider>
  );
};

export default PopupModal;
