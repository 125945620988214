import { Modal } from "@mui/material";
import React from "react";
import "./optionsModal.css";
import { StyledEngineProvider } from "@mui/material/styles";
import CustomOutlineButton from "../components/customOutlineButton";
import CustomButton from "../components/customButton";

const OptionsModal = ({
  show = false,
  closeModal = () => {},
  title = "Are you sure?",
  cancelButtonText = "No",
  successButtonText = "Yes",
  onCancel = () => {},
  onSuccess = () => {},
  cancelButtonColor = "",
  successButtonColor = "",
}) => {
  return (
    <StyledEngineProvider injectFirst>
      <Modal open={show} className="modal" onClose={closeModal}>
        <div className="parent-container">
          <div className="modal-title">{title}</div>
          <div className="buttons-container">
            <CustomOutlineButton
              additionalStyles={{ marginRight: "5%" }}
              buttonText={cancelButtonText}
              color={cancelButtonColor}
              onClick={onCancel}
            />
            <CustomButton
              additionalStyles={{ marginLeft: "5%" }}
              buttonText={successButtonText}
              color={successButtonColor}
              onClick={onSuccess}
            />
          </div>
        </div>
      </Modal>
    </StyledEngineProvider>
  );
};

export default OptionsModal;
