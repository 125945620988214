import React, { useEffect, useState } from "react";
import style from "../styles/searchElectrifiAsset.module.css";
import Header from "../components/header";
import LogoutButton from "../components/logoutButton";
import RadioButtons from "../components/radioButtons";
import HorizontalDivider from "../components/horizontalDivider";
import DividerWithText from "../components/dividerWithText";
import InputField from "../components/inputField";
import Dropdown from "../components/dropdown";
import IconButton from "@mui/material/IconButton";
import ic_search from "../icons/ic_search.png";
import CustomButton from "../components/customButton";
import CustomOutlineButton from "../components/customOutlineButton";
import { ERROR_MEDIUM, TEXT_HEADING } from "../constants/colors";
import ic_refresh from "../icons/ic_refresh.png";
import ic_right from "../icons/ic_right.png";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { auth } from "../firebase/config";
import SingleOptionModal from "../modals/singleOptionModal";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/loader";
import {
  clearDetails,
  continuePreviousDetails,
  updateDetails,
  updatePreviousData,
} from "../features/inspection/inspectionSlice";
import { addAppData, resetAppData } from "../features/appData/appDataSlice";
import { BackendAPI } from "../api";
import DynamicDropdown from "../components/dynamicDropdown";
import { rcNumberValidator } from "../functions/validationFunctions";
import OptionsModal from "../modals/optionsModal";
import { logoutUser } from "../features/user/userSlice";

const SearchElectrifiAsset = () => {
  const logout = () => {
    deleteCookie("uid");
    deleteCookie("signin-status");
    deleteCookie("name");
    dispatch(clearDetails());
    dispatch(resetAppData());
    dispatch(logoutUser());
    navigation("/login");
  };
  const [rc, setRc] = useState(null);
  const [chassis, setChassis] = useState(null);
  const [serial, setSerial] = useState(null);
  const [speedType, setSpeedType] = useState(null);
  const [completeElectrifiData, setCompleteElectrifiData] = useState();
  const [cookie, setCookie, deleteCookie] = useCookies(["electrifi-mobility"]);
  const [pageLoading, setPageLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [continueLoading, setContinueLoading] = useState(false);
  const [pageError, setPageError] = useState();
  const [continueInspectionDetails, setContinueInspectionDetails] = useState(
    {}
  );
  const [continueInspection, setContinueInspection] = useState(false);
  const [optionsModalSettings, setOptionsModalSettings] = useState({
    show: false,
    closeModal: () => {},
    title: "Are you sure?",
    cancelButtonText: "No",
    successButtonText: "Yes",
    onCancel: () => {},
    onSuccess: () => {},
    cancelButtonColor: "",
    successButtonColor: "",
  });
  const [logoutOptionSettings, setLogoutOptionSettings] = useState({
    show: false,
    title: "You have been logged out. Please login again",
    buttonText: "Login",
    onClick: logout,
  });
  const [singleOptionModalSettings, setSingleOptionModalSettings] = useState({
    show: false,
    title: "You have been logged out. Please login again",
    buttonText: "Login",
    onClick: logout,
  });
  const assetOwner = useSelector((st) => st.inspection.asset_ownership);
  const previousID = useSelector(
    (st) => st.inspection.previousData?.inspection_id
  );
  const inspectionData = useSelector((st) => st.inspection);
  const userData = useSelector((st) => st.user);
  const [optionsLink, setOptionsLink] = useState({
    rc: `/assetmanagement/asset?query_filter=registration_no&asset_type=${inspectionData.asset_type}&page_no=1&query=`,
    chassis: `/assetmanagement/asset?query_filter=chassis_number&asset_type=${inspectionData.asset_type}&page_no=1&query=`,
    battery: `/assetmanagement/asset?query_filter=chassis_number&asset_type=${inspectionData.asset_type}&page_no=1&query=`,
  });
  const navigation = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      !assetOwner ||
      (assetOwner !== "electrifi" && assetOwner !== "external")
    ) {
      navigation("/select-asset");
    }
    if (cookie && cookie["signin-status"]) {
      setTimeout(() => {
        if (!auth.currentUser) {
          setSingleOptionModalSettings({ ...logoutOptionSettings, show: true });
        } else {
          setCookie("uid", auth.currentUser.uid, {
            path: "/",
            maxAge: 60 * 60 * 24,
          });
          setCookie("signin-status", true, { path: "/", maxAge: 60 * 60 * 24 });
        }
      }, 5000);
    } else {
      setTimeout(() => {
        if (auth.currentUser) {
          setCookie("uid", auth.currentUser.uid, {
            path: "/",
            maxAge: 60 * 60 * 24,
          });
          setCookie("signin-status", true, { path: "/", maxAge: 60 * 60 * 24 });
        } else {
          setSingleOptionModalSettings({ ...logoutOptionSettings, show: true });
        }
      }, 3000);
    }
    if (assetOwner === "external" && inspectionData.asset_type !== "2w") {
      setSpeedType("high_speed");
    }
  }, []);

  useEffect(() => {
    if (continueInspection) {
      getInspectionData();
    }
  }, [continueInspection]);

  const getSearchIcon = () => {
    return (
      <div>
        <img src={ic_search} height={"20px"} width={"20px"} />
      </div>
    );
  };

  const getEndButton = () => {
    return <IconButton edge="start">{getSearchIcon()}</IconButton>;
  };

  const inspectionExistsCloseAction = () => {
    setRc(null);
    setChassis(null);
    setOptionsModalSettings({
      ...optionsModalSettings,
      show: false,
    });
  };

  const resetDetails = () => {
    setRc(null);
    setChassis(null);
    setSingleOptionModalSettings({ ...singleOptionModalSettings, show: false });
    setSubmitLoading(false);
    setPageLoading(false);
  };

  const getInspectionData = async () => {
    try {
      var token = await auth.currentUser.getIdToken();
      var configs = {
        headers: {
          Authorization: `bearer ${token}`,
        },
      };

      var resp = await BackendAPI.get(
        `/inspection/${continueInspectionDetails.inspection_id}?asset_type=${inspectionData.asset_type}`,
        configs
      );
      if (resp.status === 200) {
        setOptionsModalSettings({
          ...optionsModalSettings,
          show: false,
        });
        setSubmitLoading(false);
        setPageLoading(false);
        dispatch(updatePreviousData(resp.data.data));
        const newData = inspectionData.previousData;
        dispatch(continuePreviousDetails(newData));
        dispatch(addAppData({ inspection_type: "continue" }));
        navigation("/asset/inspection", { replace: true });
      } else {
        if (resp && resp.data && resp.data.data && resp.data.data) {
          if (resp.data.data[0].msg) setPageError(resp.data.data[0].msg);
          if (resp.data.data[0].message)
            setPageError(resp.data.data[0].message);
        }
      }
    } catch (error) {
      setSubmitLoading(false);
      setPageLoading(false);
      console.log(error);
    }
  };

  const inspectionExistsSuccessAction = () => {
    setSubmitLoading(true);
    setPageLoading(true);
    setContinueInspection(true);
  };

  const startInspection = async () => {
    if (assetOwner === "electrifi" && !rc && !chassis && !serial) {
      setPageError("Please enter either RC or Chassis Number or Asset Serial");
      return;
    } else if (
      assetOwner === "external" &&
      inspectionData.asset_type === "bat" &&
      !serial
    ) {
      setPageError("Please enter the Battery Serial number");
    } else if (
      assetOwner === "external" &&
      inspectionData.asset_type !== "bat" &&
      !speedType
    ) {
      setPageError("Please select Asset Speed Type");
      return;
    } else if (
      assetOwner === "external" &&
      inspectionData.asset_type !== "bat" &&
      !chassis
    ) {
      setPageError("Please enter Chassis Number");
      return;
    } else if (
      assetOwner === "external" &&
      inspectionData.asset_type !== "bat" &&
      !rc &&
      speedType === "high_speed"
    ) {
      setPageError("Please enter RC Number for High Speed Asset");
      return;
    }
    setSubmitLoading(true);
    var asset_uid_data = {};
    var ext_asset_data = {};
    if (assetOwner === "electrifi") {
      if (rc) {
        var tempRc = rc;
        tempRc = tempRc.replace(/[\s]+/, "");
        if (!rcNumberValidator(tempRc)) {
          setPageLoading(false);
          setSubmitLoading(false);
          setPageError("Please enter a valid RC Number");
          return;
        }
        setPageError(null);
        asset_uid_data = {
          asset_uid: String(tempRc).trim(),
          asset_uid_type: "rc",
        };
      } else if (serial) {
        asset_uid_data = {
          asset_uid: String(serial).trim(),
          asset_uid_type: "chassis",
        };
      } else {
        asset_uid_data = {
          asset_uid: String(chassis).trim(),
          asset_uid_type: "chassis",
        };
      }
    } else if (assetOwner === "external") {
      if (inspectionData.asset_type === "bat") {
        ext_asset_data = {
          ext_chassis_number: String(serial).trim(),
        };
      } else {
        ext_asset_data = {
          vehicle_speed_type: String(speedType).trim(),
          ext_chassis_number: String(chassis).trim(),
        };
        if (speedType === "high_speed") {
            var tempRc = rc;
            tempRc = tempRc.replace(/[\s]+/, "");
            if (!rcNumberValidator(tempRc)) {
              setPageLoading(false);
              setSubmitLoading(false);
              setPageError("Please enter a valid RC Number");
              return;
            }
            ext_asset_data["ext_rc_number"] = String(tempRc).trim();
        }
      }
    }
    var token = await auth.currentUser.getIdToken();
    var configs = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    var postData = {
      asset_type: inspectionData.asset_type,
      asset_owner_id: inspectionData.asset_owner_id,
      asset_ownership: inspectionData.asset_ownership,
      asset_city_id: inspectionData.asset_city_id,
      asset_address: inspectionData.asset_address,
      inspection_type: inspectionData.inspection_type,
      template_version: String(inspectionData.templateVersion),
      ...asset_uid_data,
      ...ext_asset_data,
    };
    try {
      var resp = await BackendAPI.post(
        `/inspection/?asset_type=${inspectionData.asset_type}`,
        postData,
        configs
      );
      if (resp.status === 200) {
        setSubmitLoading(false);
        setPageLoading(false);
        setPageError(null);
        var extDataComp;
        if (inspectionData.asset_ownership === "external") {
          try {
            var extData = await BackendAPI.get(
              `/inspection/asset/details?inspection_id=${resp.data.data.inspection_id}`,
              configs
            );
            if (extData.status === 200 && extData.data?.data) {
              extDataComp = extData.data.data;
              setCompleteElectrifiData(extData.data.data);
            }
          } catch (error) {
            console.log(error);
          }
        }
        dispatch(
          updateDetails({
            ...postData,
            inspection_id: resp.data.data.inspection_id,
            electrifi_data: extDataComp ? extDataComp : completeElectrifiData,
          })
        );
        dispatch(addAppData({ inspection_type: "new" }));
        navigation("/asset/inspection", { replace: true });
      } else if (resp.status === 201) {
        setContinueInspectionDetails(resp.data.data);
        setSubmitLoading(false);
        setPageLoading(false);
        setPageError(null);
        setOptionsModalSettings({
          ...optionsModalSettings,
          show: true,
          closeModal: inspectionExistsCloseAction,
          title:
            "An inspection already exists for this vehicle. Do you wish to continue?",
          onCancel: inspectionExistsCloseAction,
          onSuccess: inspectionExistsSuccessAction,
        });
      } else if (
        resp.status === 400 &&
        String(resp.data?.data[0]?.msg).includes("in-progress") &&
        String(resp.data?.data[0]?.msg).includes("another user")
      ) {
        setSingleOptionModalSettings({
          show: true,
          title:
            "An inspection for this vehicle is already ongoing by another user.",
          buttonText: "Try another ID",
          onClick: resetDetails,
        });
      } else {
        setSubmitLoading(false);
        setPageLoading(false);
        if (resp && resp.data && resp.data.data) {
          if (resp.data.data[0].message) {
            setPageError(resp.data.data[0].message);
          } else if (resp.data.data[0].msg) {
            setPageError(resp.data.data[0].msg);
          }
        } else {
          setPageError("Something went wrong! Please try again.");
        }
      }
    } catch (error) {
      setSubmitLoading(false);
      setPageError("Something went wrong! Please try again");
      console.log(error);
    }
  };

  const getLeftIcon = () => {
    return (
      <div className={style.button_left_fixed_icon}>
        <img className={style.button_icon} src={ic_refresh} />
      </div>
    );
  };

  const getRightIcon = () => {
    return (
      <div className={style.button_right_fixed_icon}>
        <img className={style.button_icon} src={ic_right} />
      </div>
    );
  };

  const continuePreviousInspection = async () => {
    setContinueLoading(true);
    const details = inspectionData.previousData;
    const inspectionId = details.inspection_id;
    var token = await auth.currentUser.getIdToken();
    var configs = {
      headers: {
        Authorization: `bearer ${token}`,
      },
    };

    try {
      var resp = await BackendAPI.get(
        `/inspection/asset/details?inspection_id=${inspectionId}`,
        configs
      );
      if (resp?.data?.data) {
        dispatch(
          updateDetails({
            electrifi_data: resp.data.data,
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
    dispatch(continuePreviousDetails(details));
    dispatch(addAppData({ inspection_type: "continue" }));
    navigation("/asset/inspection", { replace: true });
  };

  return (
    <div className={style.container}>
      <Header
        headerDescription={
          cookie && cookie["name"]
            ? cookie["name"]
            : userData.name
            ? userData.name
            : ""
        }
        endItem={<LogoutButton />}
        marginHorizontal="4%"
        marginTop="4%"
        width="92%"
      />
      <OptionsModal {...optionsModalSettings} />
      <SingleOptionModal
        show={singleOptionModalSettings.show}
        title={singleOptionModalSettings.title}
        buttonText={singleOptionModalSettings.buttonText}
        onClick={singleOptionModalSettings.onClick}
      />
      <HorizontalDivider />
      <div className={style.asset_owner_container}>
        <RadioButtons
          label={"Whose Asset is it?"}
          options={[
            assetOwner === "electrifi"
              ? { value: "electrifi", name: "Electrifi Asset" }
              : { value: "external", name: "External Asset" },
          ]}
          selectedOption={assetOwner}
        />
        {assetOwner === "electrifi" ? (
          inspectionData.asset_type === "bat" ? (
            <>
              <div className={style.search_rc_container}>
                <p className="field-label">{"Search Asset"}</p>
                <div className={style.search_rc}>
                  <DynamicDropdown
                    url={
                      serial && serial.length > 3
                        ? optionsLink.battery + serial
                        : ""
                    }
                    placeholder="Search by Asset ID"
                    value={serial}
                    freeSolo={true}
                    clearIcon={false}
                    onTextChange={(e, val) => {
                      setSerial(val);
                    }}
                    onChange={(e) => {
                      setCompleteElectrifiData(e);
                      setSerial(e.chassisNumber);
                    }}
                    fieldKey="chassis"
                  />
                  <div style={{ position: "absolute", right: "5%" }}>
                    {getEndButton()}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className={style.search_rc_container}>
                <p className="field-label">{"Search Asset"}</p>
                <div className={style.search_rc}>
                  <DynamicDropdown
                    url={rc && rc.length > 3 ? optionsLink.rc + rc : ""}
                    placeholder="Search by RC"
                    value={rc}
                    freeSolo={true}
                    clearIcon={false}
                    onTextChange={(e, val) => {
                      setChassis(null);
                      setRc(val);
                    }}
                    onChange={(e) => {
                      setCompleteElectrifiData(e);
                      setChassis(null);
                      setRc(e.rcNumber);
                    }}
                    fieldKey="rc"
                  />
                  <div style={{ position: "absolute", right: "5%" }}>
                    {getEndButton()}
                  </div>
                </div>
              </div>
              <DividerWithText />
              <div className={style.search_chassis_container}>
                <div className={style.search_chassis}>
                  <DynamicDropdown
                    url={
                      chassis && chassis.length > 3
                        ? optionsLink.chassis + chassis
                        : ""
                    }
                    placeholder="Search by Chassis"
                    value={chassis}
                    freeSolo={true}
                    clearIcon={false}
                    onTextChange={(e, val) => {
                      setRc(null);
                      setChassis(val);
                    }}
                    onChange={(e) => {
                      setCompleteElectrifiData(e);
                      setRc(null);
                      setChassis(e.chassisNumber);
                    }}
                    fieldKey="chassis"
                  />
                  <div style={{ position: "absolute", right: "5%" }}>
                    {getEndButton()}
                  </div>
                </div>
              </div>
            </>
          )
        ) : assetOwner === "external" ? (
          inspectionData.asset_type === "bat" ? (
            <>
              <div className={style.vehicle_speed_type}>
                <p className="field-label">{"Enter Asset Details"}</p>
                <div className={style.search_chassis_container}>
                  <div className={style.search_chassis}>
                    <InputField
                      placeholder="Battery Serial Number"
                      value={serial}
                      onChange={(e) => {
                        setSerial(e.target.value);
                      }}
                      capital={"true"}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className={style.vehicle_speed_type}>
                <p className="field-label">{"Enter Asset Details"}</p>
                <div className={style.vehicle_speed_type}>
                  <Dropdown
                    options={
                      inspectionData.asset_type === "2w"
                        ? [
                            {
                              name: "High Speed",
                              value: "high_speed",
                            },
                            {
                              name: "Slow Speed",
                              value: "slow_speed",
                            },
                          ]
                        : [
                            {
                              name: "High Speed",
                              value: "high_speed",
                            },
                          ]
                    }
                    placeholder={"Asset Speed Type"}
                    value={speedType}
                    onChange={(e) => {
                      setSpeedType(e.value);
                      setRc(e.value === "slow_speed" ? null : rc);
                    }}
                  />
                </div>
              </div>
              <div className={style.search_chassis_container}>
                <div className={style.search_chassis}>
                  <InputField
                    placeholder="Vehicle Chassis No."
                    value={chassis}
                    onChange={(e) => {
                      setChassis(e.target.value);
                    }}
                    capital={"true"}
                  />
                </div>
              </div>
              <div className={style.search_rc_container}>
                <div className={style.search_rc}> 
                  <InputField
                    placeholder={
                      "Vehicle RC" + (speedType == "high_speed" ? "*" : "")
                    }
                    value={speedType === "high_speed" ? rc : null}
                    onChange={(e) => {
                      setRc(speedType === "high_speed" ? e.target.value : null);
                    }}
                    hidden={speedType !== "high_speed" ? true : false}
                    capital={true}
                  />
                </div>
              </div>
            </>
          )
        ) : null}
        {pageError ? (
          <div style={{ width: "100%", textAlign: "center" }}>
            <p style={{ fontSize: "small", color: ERROR_MEDIUM }}>
              {pageError}
            </p>
          </div>
        ) : null}
        <div className={style.continue_button_container}>
          <div className={style.continue_button}>
            <CustomButton
              buttonText={submitLoading ? <Loader /> : "Start Inspection"}
              onClick={() => {
                if (!pageLoading && !submitLoading && !continueLoading) {
                  startInspection();
                }
              }}
            />
          </div>
        </div>
        {previousID ? (
          <div className={style.continue_inspection_button_container}>
            <div className={style.continue_inspection_button}>
              <CustomOutlineButton
                buttonText={
                  continueLoading ? (
                    <Loader />
                  ) : (
                    `Continue Ongoing Inspection (ID ${previousID})`
                  )
                }
                variant="outlined"
                color="transparent"
                tappedColor="#F7F8F9"
                borderColor="#00357C"
                tappedBorderColor="#00357C"
                textColor={TEXT_HEADING}
                fontSize="12px"
                startIcon={getLeftIcon()}
                endIcon={getRightIcon()}
                onClick={() => {
                  if (!pageLoading && !submitLoading && !continueLoading) {
                    continuePreviousInspection();
                  }
                }}
              />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default SearchElectrifiAsset;
