import React, { useRef } from "react";
import "./dateInput.css";
import InputField from "./inputField";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";

const DateInput = ({
  validation,
  value = "",
  disabled = false,
  onChange = () => {},
  isError = false,
  errorMessage,
  id = "date-input",
  placeholder = "",
  pastDate = true,
  futureDate = true,
  supportingPhoto = false,
  supportingImage,
  hidden = false,
  key1 = "date-input",
  required = false,
  onSupportingImageChange = () => {},
}) => {
  const current_date = dayjs();
  const pickerRef = useRef();

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {hidden ? (
        <></>
      ) : (
        <>
          <InputField
            validation={validation}
            value={value}
            disabled={disabled}
            isError={isError}
            placeholder={placeholder}
            errorMessage={errorMessage}
            id={id}
            key1={key1}
            supportingPhoto={supportingPhoto}
            supportingImage={supportingImage}
            onSupportingImageChange={onSupportingImageChange}
            onClick={() => pickerRef.current?.click()}
            onFocus={() => pickerRef.current?.click()}
          />
          <MobileDatePicker
            sx={{ display: "none" }}
            ref={pickerRef}
            onAccept={onChange}
            value={dayjs(new Date(value, "dd/mm/yyyy"))}
            disablePast={!pastDate}
            disableFuture={!futureDate}
          />
        </>
      )}
    </LocalizationProvider>
  );
};

export default DateInput;
