// PRIMARY COLOR 1
export const PRIMARY_1_SHADE_1 = '#00357C';
export const PRIMARY_1_SHADE_2 = '#809ABD';
export const PRIMARY_1_SHADE_3 = '#CCD7E5';
export const PRIMARY_1_SHADE_4 = '#E5EBF2';
export const PRIMARY_BUTTON_TAPPED = '#002A61';

// PRIMARY COLOR 2
export const PRIMARY_2_SHADE_1 = '#00B8B7';
export const PRIMARY_2_SHADE_2 = '#80DBDB';
export const PRIMARY_2_SHADE_3 = '#CCF1F1';
export const PRIMARY_2_SHADE_4 = '#E5F8F8';

// SECONDARY COLOR 1
export const SECONDARY_1_SHADE_1 = '#EA5A00';
export const SECONDARY_1_SHADE_2 = '#F5AC80';
export const SECONDARY_1_SHADE_3 = '#FBDECC';
export const SECONDARY_1_SHADE_4 = '#FDEEE5';

// SECONDARY COLOR 2
export const SECONDARY_2_SHADE_1 = '#44485B';
export const SECONDARY_2_SHADE_2 = '#A1A3AD';
export const SECONDARY_2_SHADE_3 = '#DADADE';
export const SECONDARY_2_SHADE_4 = '#ECEDEF';

// SECONDARY COLOR 3
export const SECONDARY_3_SHADE_1 = '#FFB629';
export const SECONDARY_3_SHADE_2 = '#FFDB94';
export const SECONDARY_3_SHADE_3 = '#FFF0D4';
export const SECONDARY_3_SHADE_4 = '#FFF8EA';

// GREYS
export const GREY_LOADING = '#E8EDF6';
export const GREY_STROKE = '#E6E6E6';
export const GREY_SECONDARY_BACKGROUND = '#F7F8F9';
export const GREY_BG = '#F7F7FC';

// TEXT COLORS
export const TEXT_HEADING = '#373A46';
export const TEXT_SMALL = '#696B74';
export const TEXT_SECONDARY = '#919598';

// ERROR COLORS
export const ERROR_DARK = '#EF3C3C';
export const ERROR_MEDIUM = '#F79D9D';
export const ERROR_LIGHT = '#FEF1F1';

// SUCCESS COLORS
export const SUCCESS_DARK = '#00B8B7';
export const SUCCESS_MEDIUM = '#80DBDB';
export const SUCCESS_LIGHT = '#EDFAFA';

// GRADIENT COLORS
export const GRADIENT_BLUE_LIGHT = '#005CD7';
export const GRADIENT_BLUE_DARK = '#00357C';
export const GRADIENT_YELLOW_LIGHT = '#F3D55F';
export const GRADIENT_YELLOW_DARK = '#F99941';
export const GRADIENT_GREY_LIGHT = '#4C5168';
export const GRADIENT_GREY_DARK = '#373A46';

// COMMON COLORS
export const BLACK = '#000000';
export const WHITE = '#FFFFFF';
