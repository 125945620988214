export const required = (value, required = false) => {
  if (!required) {
    return true;
  } else if (required && value) {
    return true;
  } else {
    return false;
  }
};

export const formatValidator = (value, datatype = "text") => {
  if (datatype === "text") {
    return true;
  } else if (datatype === "number") {
    if (/^[-]{0,1}[\d]+[.]{0,1}[\d]+$/.test(value)) {
      return true;
    } else {
      return false;
    }
  }
};

export const checkPastStrictDate = (date, compareDate) => {
  if (date < compareDate) {
    return true;
  } else {
    return false;
  }
};

export const checkPastDate = (date, compareDate) => {
  if (date <= compareDate) {
    return true;
  } else {
    return false;
  }
};

export const checkFutureStrictDate = (date, compareDate) => {
  if (date > compareDate) {
    return true;
  } else {
    return false;
  }
};

export const checkFutureDate = (date, compareDate) => {
  if (date >= compareDate) {
    return true;
  } else {
    return false;
  }
};

export const checkLengthStrict = (value, len) => {
  if (String(value ? value : "").length === len) {
    return true;
  } else {
    return false;
  }
};

export const checkGreaterLengthStrict = (value, len) => {
  if (String(value ? value : "").length > len) {
    return true;
  } else {
    return false;
  }
};

export const checkGreaterLength = (value, len) => {
  if (String(value ? value : "").length >= len) {
    return true;
  } else {
    return false;
  }
};

export const checkSmallerLengthStrict = (value, len) => {
  if (String(value ? value : "").length < len) {
    return true;
  } else {
    return false;
  }
};

export const checkSmallerLength = (value, len) => {
  if (String(value ? value : "").length <= len) {
    return true;
  } else {
    return false;
  }
};

export const limitMaximumValue = (value, maxVal) => {
  if (!value || value <= maxVal) {
    return true;
  } else {
    return false;
  }
};

export const limitMinimumValue = (value, minVal) => {
  if (!value || value >= minVal) {
    return true;
  } else {
    return false;
  }
};

export const getDependencyOverrides = (
  dependencyObj,
  dataObj,
  inspectionData
) => {
  try {
    var finalOverrides = {};
    for (const [index, value] of Object.entries(dependencyObj)) {
      const indLevels = index.split(".");
      var compData = dataObj;
      for (var i of indLevels) {
        if (compData) {
          compData = compData[i];
        }
      }
      if (compData && value.value === compData) {
        finalOverrides = { ...finalOverrides, ...value };
      }
      delete finalOverrides["value"];
    }
    for (const [index, value] of Object.entries(dependencyObj)) {
      var compData = inspectionData;
      if (compData) {
        compData = compData[index];
      }
      if (compData && value.value === compData) {
        finalOverrides = { ...finalOverrides, ...value };
      }
      delete finalOverrides["value"];
    }
    return finalOverrides;
  } catch (error) {
    console.log(error);
    return {};
  }
};

export const rcNumberValidator = (rc) => {
  return /^[A-Z]{2}[\s]*[0-9]{1,2}[\s]*(?:[A-Z])?(?:[A-Z]*)?[\s]*[0-9]{4}$/i.test(
    rc
  );
};

export const datatypeValidator = (value, type) => {
  switch (type) {
    case "integer":
      try {
        console.log(value, value.toString().indexOf(".") === -1);
        if (!isNaN(value) && value.toString().indexOf(".") === -1) {
          return true;
        } else {
          return false;
        }
      } catch (error) {
        console.log(error);
        return false;
      }
    case "float":
      if (!isNaN(value)) {
        return true;
      } else {
        return false;
      }
    default:
      return true;
  }
};
