import React from "react";
import { StyledEngineProvider } from "@mui/material/styles";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  PRIMARY_1_SHADE_1,
  PRIMARY_BUTTON_TAPPED,
  WHITE,
} from "../constants/colors";
import "./customTextButton.css";

const CustomTextButton = ({
  buttonText = "Submit",
  color = PRIMARY_1_SHADE_1,
  tappedColor = PRIMARY_BUTTON_TAPPED,
  textColor = WHITE,
  borderColor = PRIMARY_1_SHADE_1,
  tappedBorderColor = PRIMARY_BUTTON_TAPPED,
  onClick = () => {},
  disabled = false,
  textSize = "medium",
  additionalStyles = {},
  fontSize = "medium",
  startIcon,
  endIcon,
}) => {
  const BootstrapButton = styled(Button)({
    boxShadow: "none",
    textTransform: "none",
    fontSize: fontSize,
    outline: 0,
    padding: "4px 0 4px 0",
    border: "none",
    backgroundColor: color,
    color: textColor,
    borderColor: borderColor,
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      backgroundColor: tappedColor,
      borderColor: tappedBorderColor,
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: tappedColor,
      borderColor: tappedBorderColor,
    },
    "&:focus": {},
  });

  return (
    <StyledEngineProvider injectFirst>
      <div className="custom-text-button-container">
        {startIcon}
        <BootstrapButton fullWidth variant="text">
          {buttonText}
        </BootstrapButton>
        {endIcon}
      </div>
    </StyledEngineProvider>
  );
};

export default CustomTextButton;
